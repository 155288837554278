/*!
 * Bootstrap v2.0.3
 *
 * Copyright 2012 Twitter, Inc
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Designed and built with all the love in the world @twitter by @mdo and @fat.
 */


@import url(http://fonts.googleapis.com/css?family=Cuprum);
@import url(http://fonts.googleapis.com/css?family=Imprima);
@import url(http://fonts.googleapis.com/css?family=Handlee);
body{
    background: url("../img/patterns/brillant.png");
    font: 14px/1.6 'Imprima', Verdana, Helvetica, sans-serif;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
    display: block;
}
audio,
canvas,
video {
    display: inline-block;
    *display: inline;
    *zoom: 1;
}
audio:not([controls]) {
    display: none;
}
html {
    font-size: 100%;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}
a:focus {
    outline: thin dotted #333;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}
a:hover,
a:active {
    outline: 0;
}
sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}
sup {
    top: -0.5em;
}
sub {
    bottom: -0.25em;
}
img {
    max-width: 100%;
    vertical-align: middle;
    border: 0;
    -ms-interpolation-mode: bicubic;
}
button,
input,
select,
textarea {
    margin: 0;
    font-size: 100%;
    vertical-align: middle;
}
button,
input {
    *overflow: visible;
    line-height: normal;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
    padding: 0;
    border: 0;
}
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
    cursor: pointer;
    -webkit-appearance: button;
}
input[type="search"] {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-appearance: textfield;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
}
textarea {
    overflow: auto;
    vertical-align: top;
}
.clearfix {
    *zoom: 1;
}
.clearfix:before,
.clearfix:after {
    display: table;
    content: "";
}
.clearfix:after {
    clear: both;
}
.hide-text {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}
.input-block-level {
    display: block;
    width: 100%;
    min-height: 28px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
}
body {
    margin: 0;
    font-family: "Imprima", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 18px;
    color: #333333;
    background-color: #ffffff;
}
a {
    color: #0088cc;
    text-decoration: none;
}
a:hover {
    color: #005580;
    text-decoration: underline;
}
.row {
    margin-left: -20px;
    *zoom: 1;
}
.row:before,
.row:after {
    display: table;
    content: "";
}
.row:after {
    clear: both;
}
[class*="span"] {
    float: left;
    margin-left: 20px;
}
.container,
.navbar-fixed-top .container,
.navbar-fixed-bottom .container {
    width: 940px;
}
.span12 {
    width: 940px;
}
.span11 {
    width: 860px;
}
.span10 {
    width: 780px;
}
.span9 {
    width: 700px;
}
.span8 {
    width: 620px;
}
.span7 {
    width: 540px;
}
.span6 {
    width: 460px;
}
.span5 {
    width: 380px;
}
.span4 {
    width: 300px;
}
.span3 {
    width: 220px;
}
.span2 {
    width: 140px;
}
.span1 {
    width: 60px;
}
.offset12 {
    margin-left: 980px;
}
.offset11 {
    margin-left: 900px;
}
.offset10 {
    margin-left: 820px;
}
.offset9 {
    margin-left: 740px;
}
.offset8 {
    margin-left: 660px;
}
.offset7 {
    margin-left: 580px;
}
.offset6 {
    margin-left: 500px;
}
.offset5 {
    margin-left: 420px;
}
.offset4 {
    margin-left: 340px;
}
.offset3 {
    margin-left: 260px;
}
.offset2 {
    margin-left: 180px;
}
.offset1 {
    margin-left: 100px;
}
.row-fluid {
    width: 100%;
    *zoom: 1;
}
.row-fluid:before,
.row-fluid:after {
    display: table;
    content: "";
}
.row-fluid:after {
    clear: both;
}
.row-fluid [class*="span"] {
    display: block;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    float: left;
    margin-left: 2.127659574%;
    *margin-left: 2.0744680846382977%;
}
.row-fluid [class*="span"]:first-child {
    margin-left: 0;
}
.row-fluid .span12 {
    width: 99.99999998999999%;
    *width: 99.94680850063828%;
}
.row-fluid .span11 {
    width: 91.489361693%;
    *width: 91.4361702036383%;
}
.row-fluid .span10 {
    width: 82.97872339599999%;
    *width: 82.92553190663828%;
}
.row-fluid .span9 {
    width: 74.468085099%;
    *width: 74.4148936096383%;
}
.row-fluid .span8 {
    width: 65.95744680199999%;
    *width: 65.90425531263828%;
}
.row-fluid .span7 {
    width: 57.446808505%;
    *width: 57.3936170156383%;
}
.row-fluid .span6 {
    width: 48.93617020799999%;
    *width: 48.88297871863829%;
}
.row-fluid .span5 {
    width: 40.425531911%;
    *width: 40.3723404216383%;
}
.row-fluid .span4 {
    width: 31.914893614%;
    *width: 31.8617021246383%;
}
.row-fluid .span3 {
    width: 23.404255317%;
    *width: 23.3510638276383%;
}
.row-fluid .span2 {
    width: 14.89361702%;
    *width: 14.8404255306383%;
}
.row-fluid .span1 {
    width: 6.382978723%;
    *width: 6.329787233638298% !important;
}
.row-fluid .offset12, .row-fluid > .offset12[class*="span"] {
    margin-left: 102.127659564% !important;
    *margin-left: 102.02127658527658% !important;
}
.row-fluid .offset11, .row-fluid > .offset11[class*="span"] {
    margin-left: 93.61702126700001% !important;
    *margin-left: 93.5106382882766% !important;
}
.row-fluid .offset10, .row-fluid > .offset10[class*="span"] {
    margin-left: 85.10638297% !important;
    *margin-left: 84.99999999127658% !important;
}
.row-fluid .offset9, .row-fluid > .offset9[class*="span"] {
    margin-left: 76.59574467300001% !important;
    *margin-left: 76.4893616942766% !important;
}
.row-fluid .offset8, .row-fluid > .offset8[class*="span"] {
    margin-left: 68.085106376% !important;
    *margin-left: 67.97872339727658% !important;
}
.row-fluid .offset7, .row-fluid > .offset7[class*="span"] {
    margin-left: 59.574468079% !important;
    *margin-left: 59.468085100276596% !important;
}
.row-fluid .offset6, .row-fluid > .offset6[class*="span"] {
    margin-left: 51.06382978199999% !important;
    *margin-left: 50.95744680327659% !important;
}
.row-fluid .offset5, .row-fluid > .offset5[class*="span"] {
    margin-left: 42.553191485% !important;
    *margin-left: 42.4468085062766% !important;
}
.row-fluid .offset4, .row-fluid > .offset4[class*="span"] {
    margin-left: 34.042553188% !important;
    *margin-left: 33.9361702092766% !important;
}
.row-fluid .offset3, .row-fluid > .offset3[class*="span"] {
    margin-left: 25.531914891% !important;
    *margin-left: 25.425531912276597% !important;
}
.row-fluid .offset2, .row-fluid > .offset2[class*="span"] {
    margin-left: 17.021276594% !important;
    *margin-left: 16.914893615276597% !important;
}
.row-fluid .offset1, .row-fluid > .offset1[class*="span"] {
    margin-left: 8.510638297% !important;
    *margin-left: 8.404255318276597% !important;
}
.container {
    margin-right: auto;
    margin-left: auto;
    *zoom: 1;
}
.container:before,
.container:after {
    display: table;
    content: "";
}
.container:after {
    clear: both;
}
.container-fluid {
    padding-right: 20px;
    padding-left: 20px;
    *zoom: 1;
}
.container-fluid:before,
.container-fluid:after {
    display: table;
    content: "";
}
.container-fluid:after {
    clear: both;
}
p {
    margin: 0 0 9px;
    font: 14px/1.6 'Imprima', Verdana, Helvetica, sans-serif;
    color: #232525;
}

p small {
    font-size: 11px;
    color: #999999;
}
.lead {
    margin-bottom: 18px;
    font-size: 20px;
    font-weight: 200;
    line-height: 27px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    font: 26px/1.2 'Cuprum', Georgia, serif;
    color: inherit;
    text-rendering: optimizelegibility;
}
h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
    font-weight: normal;
    color: #999999;
}
h1 {
    font-size: 26px;
    line-height: 36px;
}
h1 small {
    font-size: 18px;
}
h2 {
    font-size: 22px;
    line-height: 36px;
}
h2 small {
    font-size: 18px;
}
h3 {
    font-size: 18px;
    line-height: 27px;
}
h3 small {
    font-size: 14px;
}
h4,
h5,
h6 {
    line-height: 18px;
}
h4 {
    font-size: 14px;
}
h4 small {
    font-size: 12px;
}
h5 {
    font-size: 12px;
}
h6 {
    font-size: 11px;
    color: #999999;
    text-transform: uppercase;
}
.page-header {
    padding-bottom: 17px;
    margin: 18px 0;
    border-bottom: 1px solid #eeeeee;
}
.page-header h1 {
    line-height: 1;
}
ul,
ol {
    padding: 0;
    margin: 0 0 9px 25px;
}
ul ul,
ul ol,
ol ol,
ol ul {
    margin-bottom: 0;
}
ul {
    list-style: disc;
}
ol {
    list-style: decimal;
}
li {
    line-height: 25px;
}
ul.unstyled,
ol.unstyled {
    margin-left: 0;
    list-style: none;
}

ul.circle{list-style: circle;}

ul.square{list-style: square;}
ul.lower-roman{list-style: lower-roman;}
ul.upper-roman{list-style: upper-roman;}
ul.lower-alpha{list-style: lower-alpha;}
ul.upper-alpha{list-style: upper-alpha;}
ul.square{list-style: square;}
ul.square{list-style: square;}

dl {
    margin-bottom: 18px;
}
dt,
dd {
    line-height: 25px;
}
dt {
    font-weight: bold;
    line-height: 25px;
}
dd {
    margin-left: 9px;
}
.dl-horizontal dt {
    float: left;
    width: 120px;
    clear: left;
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.dl-horizontal dd {
    margin-left: 130px;
}
hr {
    margin: 18px 0;
    border: 0;
    border-top: 1px solid #eeeeee;
    border-bottom: 1px solid #ffffff;
}
strong {
    font-weight: bold;
}
em {
    font-style: italic;
}
.muted {
    color: #999999;
}
abbr[title] {
    cursor: help;
    border-bottom: 1px dotted #ddd;
}
abbr.initialism {
    font-size: 90%;
    text-transform: uppercase;
}
blockquote {
    padding: 0 0 0 15px;
    margin: 0 0 18px;
    border-left: 5px solid #eeeeee;
}
blockquote p, blockquote.quoted p {
    margin-bottom: 0;
    font: 15px/1.8 'Handlee',cursive;
    color: #434343;
}
blockquote small {
    display: block;
    line-height: 18px;
    color: #999999;
}

blockquote small:before {
    content: '\2014 \00A0';
}
blockquote.pull-right {
    float: right;
    padding-right: 15px;
    padding-left: 0;
    border-right: 5px solid #eeeeee;
    border-left: 0;
}

blockquote.quoted{
    border-left: none;
    position: relative;
    padding: 0 0 0 15px;
    margin: 0 0 18px;
}

blockquote.quoted p{
    padding-left: 5px;
}

blockquote.quoted:before,
blockquote.quoted:after{
    font-size: 40px;
    color: #bfbfbf;
    font-style: italic;
    font-family: sans-serif;
}

blockquote.quoted:before{
    content:'“';
    position: absolute;
    top: 10px;
    left: -3px;
}

blockquote.quoted:after{
    content:'”';
    position: absolute;
    bottom: -20px;
    right: 0;
    float: right;
}

blockquote.pull-right p,
blockquote.pull-right small {
    text-align: right;
}
q:before,
q:after,
blockquote:before,
blockquote:after {
    content: "";
}
address {
    display: block;
    margin-bottom: 18px;
    font-style: normal;
    line-height: 18px;
}
small {
    font-size: 100%;
}
cite {
    font-style: normal;
}
code,
pre {
    padding: 0 3px 2px;
    font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
    font-size: 12px;
    color: #333333;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}
code {
    padding: 2px 4px;
    color: #d14;
    background-color: #f7f7f9;
    border: 1px solid #e1e1e8;
}
pre {
    display: block;
    padding: 8.5px;
    margin: 0 0 9px;
    font-size: 12.025px;
    line-height: 18px;
    word-break: break-all;
    word-wrap: break-word;
    white-space: pre;
    white-space: pre-wrap;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.15);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}
pre.prettyprint {
    margin-bottom: 18px;
}
pre code {
    padding: 0;
    color: inherit;
    background-color: transparent;
    border: 0;
}
.pre-scrollable {
    max-height: 340px;
    overflow-y: scroll;
}
form {
    margin: 0 0 18px;
}
fieldset {
    padding: 0;
    margin: 0;
    border: 0;
}
legend {
    display: block;
    width: 100%;
    padding: 0;
    margin-bottom: 27px;
    font-size: 19.5px;
    line-height: 36px;
    color: #333333;
    border: 0;
    border-bottom: 1px solid #eee;
}
legend small {
    font-size: 13.5px;
    color: #999999;
}
label,
input,
button,
select,
textarea {
    font-size: 13px;
    font-weight: normal;
    line-height: 18px;
}
input,
button,
select,
textarea {
    font: 16px/1.6 'Imprima', Verdana, Helvetica, sans-serif;
    width: 480px;
    color: #232525;
}
label {
    display: block;
    margin-bottom: 5px;
    color: #333333;
}
input,
textarea,
select,
.uneditable-input {
    display: inline-block;
    width: 210px;
    padding: 4px;
    margin-bottom: 9px;
    font-size: 13px;
    line-height: 18px;
    color: #555555;
    background-color: #ffffff;
    border: 1px solid #cccccc;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}
.uneditable-textarea {
    width: auto;
    height: auto;
}
label input,
label textarea,
label select {
    display: block;
}
input[type="image"],
input[type="checkbox"],
input[type="radio"] {
    width: auto;
    height: auto;
    padding: 0;
    margin: 3px 0;
    *margin-top: 0;
    /* IE7 */

    line-height: normal;
    cursor: pointer;
    background-color: transparent;
    border: 0 \9;
    /* IE9 and down */

    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}
input[type="image"] {
    border: 0;
}
input[type="file"] {
    width: auto;
    padding: initial;
    line-height: initial;
    background-color: #ffffff;
    background-color: initial;
    border: initial;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}
input[type="button"],
input[type="reset"],
input[type="submit"] {
    width: auto;
    height: auto;
}
select,
input[type="file"] {
    height: 28px;
    /* In IE7, the height of the select element cannot be changed by height, only font-size */

    *margin-top: 4px;
    /* For IE7, add top margin to align select with labels */

    line-height: 28px;
}
input[type="file"] {
    line-height: 18px \9;
}
select {
    width: 220px;
    background-color: #ffffff;
}
select[multiple],
select[size] {
    height: auto;
}
input[type="image"] {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}
textarea {
    height: auto;
}
input[type="hidden"] {
    display: none;
}
.radio,
.checkbox {
    min-height: 18px;
    padding-left: 18px;
}
.radio input[type="radio"],
.checkbox input[type="checkbox"] {
    float: left;
    margin-left: -18px;
}
.controls > .radio:first-child,
.controls > .checkbox:first-child {
    padding-top: 5px;
}
.radio.inline,
.checkbox.inline {
    display: inline-block;
    padding-top: 5px;
    margin-bottom: 0;
    vertical-align: middle;
}
.radio.inline + .radio.inline,
.checkbox.inline + .checkbox.inline {
    margin-left: 10px;
}
input,
textarea {
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border linear 0.2s, box-shadow linear 0.2s;
    -moz-transition: border linear 0.2s, box-shadow linear 0.2s;
    -ms-transition: border linear 0.2s, box-shadow linear 0.2s;
    -o-transition: border linear 0.2s, box-shadow linear 0.2s;
    transition: border linear 0.2s, box-shadow linear 0.2s;
}
input:focus,
textarea:focus {
    border-color: rgba(82, 168, 236, 0.8);
    outline: 0;
    outline: thin dotted \9;
    /* IE6-9 */

    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(82,168,236,.6);
    -moz-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(82,168,236,.6);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(82,168,236,.6);
}
input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus,
select:focus {
    outline: thin dotted #333;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}
.input-mini {
    width: 60px;
}
.input-small {
    width: 90px;
}
.input-medium {
    width: 150px;
}
.input-large {
    width: 210px;
}
.input-xlarge {
    width: 270px;
}
.input-xxlarge {
    width: 530px;
}

.input-fluid{
    width: 80%;
}
input[class*="span"],
select[class*="span"],
textarea[class*="span"],
.uneditable-input[class*="span"],
.row-fluid input[class*="span"],
.row-fluid select[class*="span"],
.row-fluid textarea[class*="span"],
.row-fluid .uneditable-input[class*="span"] {
    float: none;
    margin-left: 0;
}
input,
textarea,
.uneditable-input {
    margin-left: 0;
}
input.span12, textarea.span12, .uneditable-input.span12 {
    width: 930px;
}
input.span11, textarea.span11, .uneditable-input.span11 {
    width: 850px;
}
input.span10, textarea.span10, .uneditable-input.span10 {
    width: 770px;
}
input.span9, textarea.span9, .uneditable-input.span9 {
    width: 690px;
}
input.span8, textarea.span8, .uneditable-input.span8 {
    width: 610px;
}
input.span7, textarea.span7, .uneditable-input.span7 {
    width: 530px;
}
input.span6, textarea.span6, .uneditable-input.span6 {
    width: 450px;
}
input.span5, textarea.span5, .uneditable-input.span5 {
    width: 370px;
}
input.span4, textarea.span4, .uneditable-input.span4 {
    width: 290px;
}
input.span3, textarea.span3, .uneditable-input.span3 {
    width: 210px;
}
input.span2, textarea.span2, .uneditable-input.span2 {
    width: 130px;
}
input.span1, textarea.span1, .uneditable-input.span1 {
    width: 50px;
}
input[disabled],
select[disabled],
textarea[disabled],
input[readonly],
select[readonly],
textarea[readonly] {
    cursor: not-allowed;
    background-color: #eeeeee;
    border-color: #ddd;
}
input[type="radio"][disabled],
input[type="checkbox"][disabled],
input[type="radio"][readonly],
input[type="checkbox"][readonly] {
    background-color: transparent;
}
.control-group.warning > label,
.control-group.warning .help-block,
.control-group.warning .help-inline {
    color: #c09853;
}
.control-group.warning input,
.control-group.warning select,
.control-group.warning textarea {
    color: #c09853;
    border-color: #c09853;
}
.control-group.warning input:focus,
.control-group.warning select:focus,
.control-group.warning textarea:focus {
    border-color: #a47e3c;
    -webkit-box-shadow: 0 0 6px #dbc59e;
    -moz-box-shadow: 0 0 6px #dbc59e;
    box-shadow: 0 0 6px #dbc59e;
}
.control-group.warning .input-prepend .add-on,
.control-group.warning .input-append .add-on {
    color: #c09853;
    background-color: #fcf8e3;
    border-color: #c09853;
}
.control-group.error > label,
.control-group.error .help-block,
.control-group.error .help-inline {
    color: #b94a48;
}
.control-group.error input,
.control-group.error select,
.control-group.error textarea {
    color: #b94a48;
    border-color: #b94a48;
}
.control-group.error input:focus,
.control-group.error select:focus,
.control-group.error textarea:focus {
    border-color: #953b39;
    -webkit-box-shadow: 0 0 6px #d59392;
    -moz-box-shadow: 0 0 6px #d59392;
    box-shadow: 0 0 6px #d59392;
}
.control-group.error .input-prepend .add-on,
.control-group.error .input-append .add-on {
    color: #b94a48;
    background-color: #f2dede;
    border-color: #b94a48;
}
.control-group.success > label,
.control-group.success .help-block,
.control-group.success .help-inline {
    color: #468847;
}
.control-group.success input,
.control-group.success select,
.control-group.success textarea {
    color: #468847;
    border-color: #468847;
}
.control-group.success input:focus,
.control-group.success select:focus,
.control-group.success textarea:focus {
    border-color: #356635;
    -webkit-box-shadow: 0 0 6px #7aba7b;
    -moz-box-shadow: 0 0 6px #7aba7b;
    box-shadow: 0 0 6px #7aba7b;
}
.control-group.success .input-prepend .add-on,
.control-group.success .input-append .add-on {
    color: #468847;
    background-color: #dff0d8;
    border-color: #468847;
}
input:focus:required:invalid,
textarea:focus:required:invalid,
select:focus:required:invalid {
    color: #b94a48;
    border-color: #ee5f5b;
}
input:focus:required:invalid:focus,
textarea:focus:required:invalid:focus,
select:focus:required:invalid:focus {
    border-color: #e9322d;
    -webkit-box-shadow: 0 0 6px #f8b9b7;
    -moz-box-shadow: 0 0 6px #f8b9b7;
    box-shadow: 0 0 6px #f8b9b7;
}
.form-actions {
    padding: 17px 20px 18px;
    margin-top: 18px;
    margin-bottom: 18px;
    background-color: #f5f5f5;
    border-top: 1px solid #ddd;
    *zoom: 1;
}
.form-actions:before,
.form-actions:after {
    display: table;
    content: "";
}
.form-actions:after {
    clear: both;
}
.uneditable-input {
    overflow: hidden;
    white-space: nowrap;
    cursor: not-allowed;
    background-color: #ffffff;
    border-color: #eee;
    -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.025);
    -moz-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.025);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.025);
}
:-moz-placeholder {
    color: #999999;
}
::-webkit-input-placeholder {
    color: #999999;
}
.help-block,
.help-inline {
    color: #555555;
}
.help-block {
    display: block;
    margin-bottom: 9px;
}
.help-inline {
    display: inline-block;
    *display: inline;
    /* IE7 inline-block hack */

    *zoom: 1;
    vertical-align: middle;
    padding-left: 5px;
}
.input-prepend,
.input-append {
    margin-bottom: 5px;
}
.input-prepend input,
.input-append input,
.input-prepend select,
.input-append select,
.input-prepend .uneditable-input,
.input-append .uneditable-input {
    position: relative;
    margin-bottom: 0;
    *margin-left: 0;
    vertical-align: middle;
    -webkit-border-radius: 0 3px 3px 0;
    -moz-border-radius: 0 3px 3px 0;
    border-radius: 0 3px 3px 0;
}
.input-prepend input:focus,
.input-append input:focus,
.input-prepend select:focus,
.input-append select:focus,
.input-prepend .uneditable-input:focus,
.input-append .uneditable-input:focus {
    z-index: 2;
}
.input-prepend .uneditable-input,
.input-append .uneditable-input {
    border-left-color: #ccc;
}
.input-prepend .add-on,
.input-append .add-on {
    display: inline-block;
    width: auto;
    height: 18px;
    min-width: 16px;
    padding: 4px 5px;
    font-weight: normal;
    line-height: 18px;
    text-align: center;
    text-shadow: 0 1px 0 #ffffff;
    vertical-align: middle;
    background-color: #eeeeee;
    border: 1px solid #ccc;
}
.input-prepend .add-on,
.input-append .add-on,
.input-prepend .btn,
.input-append .btn {
    margin-left: -1px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}
.input-prepend .active,
.input-append .active {
    background-color: #a9dba9;
    border-color: #46a546;
}
.input-prepend .add-on,
.input-prepend .btn {
    margin-right: -1px;
}
.input-prepend .add-on:first-child,
.input-prepend .btn:first-child {
    -webkit-border-radius: 3px 0 0 3px;
    -moz-border-radius: 3px 0 0 3px;
    border-radius: 3px 0 0 3px;
}
.input-append input,
.input-append select,
.input-append .uneditable-input {
    -webkit-border-radius: 3px 0 0 3px;
    -moz-border-radius: 3px 0 0 3px;
    border-radius: 3px 0 0 3px;
}
.input-append .uneditable-input {
    border-right-color: #ccc;
    border-left-color: #eee;
}
.input-append .add-on:last-child,
.input-append .btn:last-child {
    -webkit-border-radius: 0 3px 3px 0;
    -moz-border-radius: 0 3px 3px 0;
    border-radius: 0 3px 3px 0;
}
.input-prepend.input-append input,
.input-prepend.input-append select,
.input-prepend.input-append .uneditable-input {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}
.input-prepend.input-append .add-on:first-child,
.input-prepend.input-append .btn:first-child {
    margin-right: -1px;
    -webkit-border-radius: 3px 0 0 3px;
    -moz-border-radius: 3px 0 0 3px;
    border-radius: 3px 0 0 3px;
}
.input-prepend.input-append .add-on:last-child,
.input-prepend.input-append .btn:last-child {
    margin-left: -1px;
    -webkit-border-radius: 0 3px 3px 0;
    -moz-border-radius: 0 3px 3px 0;
    border-radius: 0 3px 3px 0;
}
.search-query {
    padding-right: 14px;
    padding-right: 4px \9;
    padding-left: 14px;
    padding-left: 4px \9;
    /* IE7-8 doesn't have border-radius, so don't indent the padding */

    margin-bottom: 0;
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    border-radius: 14px;
}
.form-search input,
.form-inline input,
.form-horizontal input,
.form-search textarea,
.form-inline textarea,
.form-horizontal textarea,
.form-search select,
.form-inline select,
.form-horizontal select,
.form-search .help-inline,
.form-inline .help-inline,
.form-horizontal .help-inline,
.form-search .uneditable-input,
.form-inline .uneditable-input,
.form-horizontal .uneditable-input,
.form-search .input-prepend,
.form-inline .input-prepend,
.form-horizontal .input-prepend,
.form-search .input-append,
.form-inline .input-append,
.form-horizontal .input-append {
    display: inline-block;
    *display: inline;
    /* IE7 inline-block hack */

    *zoom: 1;
    margin-bottom: 0;
}
.form-search .hide,
.form-inline .hide,
.form-horizontal .hide {
    display: none;
}
.form-search label,
.form-inline label {
    display: inline-block;
}
.form-search .input-append,
.form-inline .input-append,
.form-search .input-prepend,
.form-inline .input-prepend {
    margin-bottom: 0;
}
.form-search .radio,
.form-search .checkbox,
.form-inline .radio,
.form-inline .checkbox {
    padding-left: 0;
    margin-bottom: 0;
    vertical-align: middle;
}
.form-search .radio input[type="radio"],
.form-search .checkbox input[type="checkbox"],
.form-inline .radio input[type="radio"],
.form-inline .checkbox input[type="checkbox"] {
    float: left;
    margin-right: 3px;
    margin-left: 0;
}
.control-group {
    margin-bottom: 9px;
}
legend + .control-group {
    margin-top: 18px;
    -webkit-margin-top-collapse: separate;
}
.form-horizontal .control-group {
    margin-bottom: 18px;
    *zoom: 1;
}
.form-horizontal .control-group:before,
.form-horizontal .control-group:after {
    display: table;
    content: "";
}
.form-horizontal .control-group:after {
    clear: both;
}
.form-horizontal .control-label {
    float: left;
    width: 140px;
    padding-top: 5px;
    text-align: right;
}
.form-horizontal .controls {
    *display: inline-block;
    *padding-left: 20px;
    margin-left: 160px;
    *margin-left: 0;
}
.form-horizontal .controls:first-child {
    *padding-left: 160px;
}
.form-horizontal .help-block {
    margin-top: 9px;
    margin-bottom: 0;
}
.form-horizontal .form-actions {
    padding-left: 160px;
}
table {
    max-width: 100%;
    background-color: transparent;
    border-collapse: collapse;
    border-spacing: 0;
}
.table {
    width: 100%;
    margin-bottom: 5px;
}
.table th,
.table td {
    padding: 8px;
    line-height: 18px;
    text-align: left;
    vertical-align: top;
    border-top: 1px solid #dddddd;
}
.table th {
    font-weight: bold;
}
.table thead th {
    vertical-align: bottom;
}
.table caption + thead tr:first-child th,
.table caption + thead tr:first-child td,
.table colgroup + thead tr:first-child th,
.table colgroup + thead tr:first-child td,
.table thead:first-child tr:first-child th,
.table thead:first-child tr:first-child td {
    border-top: 0;
}
.table tbody + tbody {
    border-top: 2px solid #dddddd;
}
.table-condensed th,
.table-condensed td {
    padding: 4px 5px;
}
.table-bordered {
    border: 1px solid #dddddd;
    border-collapse: separate;
    *border-collapse: collapsed;
    border-left: 0;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}
.table-bordered th,
.table-bordered td {
    border-left: 1px solid #dddddd;
}
.table-bordered caption + thead tr:first-child th,
.table-bordered caption + tbody tr:first-child th,
.table-bordered caption + tbody tr:first-child td,
.table-bordered colgroup + thead tr:first-child th,
.table-bordered colgroup + tbody tr:first-child th,
.table-bordered colgroup + tbody tr:first-child td,
.table-bordered thead:first-child tr:first-child th,
.table-bordered tbody:first-child tr:first-child th,
.table-bordered tbody:first-child tr:first-child td {
    border-top: 0;
}
.table-bordered thead:first-child tr:first-child th:first-child,
.table-bordered tbody:first-child tr:first-child td:first-child {
    -webkit-border-top-left-radius: 4px;
    border-top-left-radius: 4px;
    -moz-border-radius-topleft: 4px;
}
.table-bordered thead:first-child tr:first-child th:last-child,
.table-bordered tbody:first-child tr:first-child td:last-child {
    -webkit-border-top-right-radius: 4px;
    border-top-right-radius: 4px;
    -moz-border-radius-topright: 4px;
}
.table-bordered thead:last-child tr:last-child th:first-child,
.table-bordered tbody:last-child tr:last-child td:first-child {
    -webkit-border-radius: 0 0 0 4px;
    -moz-border-radius: 0 0 0 4px;
    border-radius: 0 0 0 4px;
    -webkit-border-bottom-left-radius: 4px;
    border-bottom-left-radius: 4px;
    -moz-border-radius-bottomleft: 4px;
}
.table-bordered thead:last-child tr:last-child th:last-child,
.table-bordered tbody:last-child tr:last-child td:last-child {
    -webkit-border-bottom-right-radius: 4px;
    border-bottom-right-radius: 4px;
    -moz-border-radius-bottomright: 4px;
}
.table-striped tbody tr:nth-child(odd) td,
.table-striped tbody tr:nth-child(odd) th {
    background-color: #f9f9f9;
}
.table tbody tr:hover td,
.table tbody tr:hover th {
    background-color: #f5f5f5;
}
table .span1 {
    float: none;
    width: 44px;
    margin-left: 0;
}
table .span2 {
    float: none;
    width: 124px;
    margin-left: 0;
}
table .span3 {
    float: none;
    width: 204px;
    margin-left: 0;
}
table .span4 {
    float: none;
    width: 284px;
    margin-left: 0;
}
table .span5 {
    float: none;
    width: 364px;
    margin-left: 0;
}
table .span6 {
    float: none;
    width: 444px;
    margin-left: 0;
}
table .span7 {
    float: none;
    width: 524px;
    margin-left: 0;
}
table .span8 {
    float: none;
    width: 604px;
    margin-left: 0;
}
table .span9 {
    float: none;
    width: 684px;
    margin-left: 0;
}
table .span10 {
    float: none;
    width: 764px;
    margin-left: 0;
}
table .span11 {
    float: none;
    width: 844px;
    margin-left: 0;
}
table .span12 {
    float: none;
    width: 924px;
    margin-left: 0;
}
table .span13 {
    float: none;
    width: 1004px;
    margin-left: 0;
}
table .span14 {
    float: none;
    width: 1084px;
    margin-left: 0;
}
table .span15 {
    float: none;
    width: 1164px;
    margin-left: 0;
}
table .span16 {
    float: none;
    width: 1244px;
    margin-left: 0;
}
table .span17 {
    float: none;
    width: 1324px;
    margin-left: 0;
}
table .span18 {
    float: none;
    width: 1404px;
    margin-left: 0;
}
table .span19 {
    float: none;
    width: 1484px;
    margin-left: 0;
}
table .span20 {
    float: none;
    width: 1564px;
    margin-left: 0;
}
table .span21 {
    float: none;
    width: 1644px;
    margin-left: 0;
}
table .span22 {
    float: none;
    width: 1724px;
    margin-left: 0;
}
table .span23 {
    float: none;
    width: 1804px;
    margin-left: 0;
}
table .span24 {
    float: none;
    width: 1884px;
    margin-left: 0;
}
[class^="icon-"],
[class*=" icon-"] {
    display: inline-block;
    width: 14px;
    height: 14px;
    *margin-right: .3em;
    line-height: 14px;
    vertical-align: text-top;
    background-image: url("../img/glyphicons-halflings.png");
    background-position: 14px 14px;
    background-repeat: no-repeat;
}
[class^="icon-"]:last-child,
[class*=" icon-"]:last-child {
    *margin-left: 0;
}
.icon-white {
    background-image: url("../img/glyphicons-halflings-white.png");
}
.icon-glass {
    background-position: 0      0;
}
.icon-music {
    background-position: -24px 0;
}
.icon-search {
    background-position: -48px 0;
}
.icon-envelope {
    background-position: -72px 0;
}
.icon-heart {
    background-position: -96px 0;
}
.icon-star {
    background-position: -120px 0;
}
.icon-star-empty {
    background-position: -144px 0;
}
.icon-user {
    background-position: -168px 0;
}
.icon-film {
    background-position: -192px 0;
}
.icon-th-large {
    background-position: -216px 0;
}
.icon-th {
    background-position: -240px 0;
}
.icon-th-list {
    background-position: -264px 0;
}
.icon-ok {
    background-position: -288px 0;
}
.icon-remove {
    background-position: -312px 0;
}
.icon-zoom-in {
    background-position: -336px 0;
}
.icon-zoom-out {
    background-position: -360px 0;
}
.icon-off {
    background-position: -384px 0;
}
.icon-signal {
    background-position: -408px 0;
}
.icon-cog {
    background-position: -432px 0;
}
.icon-trash {
    background-position: -456px 0;
}
.icon-home {
    background-position: 0 -24px;
}
.icon-file {
    background-position: -24px -24px;
}
.icon-time {
    background-position: -48px -24px;
}
.icon-road {
    background-position: -72px -24px;
}
.icon-download-alt {
    background-position: -96px -24px;
}
.icon-download {
    background-position: -120px -24px;
}
.icon-upload {
    background-position: -144px -24px;
}
.icon-inbox {
    background-position: -168px -24px;
}
.icon-play-circle {
    background-position: -192px -24px;
}
.icon-repeat {
    background-position: -216px -24px;
}
.icon-refresh {
    background-position: -240px -24px;
}
.icon-list-alt {
    background-position: -264px -24px;
}
.icon-lock {
    background-position: -287px -24px;
}
.icon-flag {
    background-position: -312px -24px;
}
.icon-headphones {
    background-position: -336px -24px;
}
.icon-volume-off {
    background-position: -360px -24px;
}
.icon-volume-down {
    background-position: -384px -24px;
}
.icon-volume-up {
    background-position: -408px -24px;
}
.icon-qrcode {
    background-position: -432px -24px;
}
.icon-barcode {
    background-position: -456px -24px;
}
.icon-tag {
    background-position: 0 -48px;
}
.icon-tags {
    background-position: -25px -48px;
}
.icon-book {
    background-position: -48px -48px;
}
.icon-bookmark {
    background-position: -72px -48px;
}
.icon-print {
    background-position: -96px -48px;
}
.icon-camera {
    background-position: -120px -48px;
}
.icon-font {
    background-position: -144px -48px;
}
.icon-bold {
    background-position: -167px -48px;
}
.icon-italic {
    background-position: -192px -48px;
}
.icon-text-height {
    background-position: -216px -48px;
}
.icon-text-width {
    background-position: -240px -48px;
}
.icon-align-left {
    background-position: -264px -48px;
}
.icon-align-center {
    background-position: -288px -48px;
}
.icon-align-right {
    background-position: -312px -48px;
}
.icon-align-justify {
    background-position: -336px -48px;
}
.icon-list {
    background-position: -360px -48px;
}
.icon-indent-left {
    background-position: -384px -48px;
}
.icon-indent-right {
    background-position: -408px -48px;
}
.icon-facetime-video {
    background-position: -432px -48px;
}
.icon-picture {
    background-position: -456px -48px;
}
.icon-pencil {
    background-position: 0 -72px;
}
.icon-map-marker {
    background-position: -24px -72px;
}
.icon-adjust {
    background-position: -48px -72px;
}
.icon-tint {
    background-position: -72px -72px;
}
.icon-edit {
    background-position: -96px -72px;
}
.icon-share {
    background-position: -120px -72px;
}
.icon-check {
    background-position: -144px -72px;
}
.icon-move {
    background-position: -168px -72px;
}
.icon-step-backward {
    background-position: -192px -72px;
}
.icon-fast-backward {
    background-position: -216px -72px;
}
.icon-backward {
    background-position: -240px -72px;
}
.icon-play {
    background-position: -264px -72px;
}
.icon-pause {
    background-position: -288px -72px;
}
.icon-stop {
    background-position: -312px -72px;
}
.icon-forward {
    background-position: -336px -72px;
}
.icon-fast-forward {
    background-position: -360px -72px;
}
.icon-step-forward {
    background-position: -384px -72px;
}
.icon-eject {
    background-position: -408px -72px;
}
.icon-chevron-left {
    background-position: -432px -72px;
}
.icon-chevron-right {
    background-position: -456px -72px;
}
.icon-plus-sign {
    background-position: 0 -96px;
}
.icon-minus-sign {
    background-position: -24px -96px;
}
.icon-remove-sign {
    background-position: -48px -96px;
}
.icon-ok-sign {
    background-position: -72px -96px;
}
.icon-question-sign {
    background-position: -96px -96px;
}
.icon-info-sign {
    background-position: -120px -96px;
}
.icon-screenshot {
    background-position: -144px -96px;
}
.icon-remove-circle {
    background-position: -168px -96px;
}
.icon-ok-circle {
    background-position: -192px -96px;
}
.icon-ban-circle {
    background-position: -216px -96px;
}
.icon-arrow-left {
    background-position: -240px -96px;
}
.icon-arrow-right {
    background-position: -264px -96px;
}
.icon-arrow-up {
    background-position: -289px -96px;
}
.icon-arrow-down {
    background-position: -312px -96px;
}
.icon-share-alt {
    background-position: -336px -96px;
}
.icon-resize-full {
    background-position: -360px -96px;
}
.icon-resize-small {
    background-position: -384px -96px;
}
.icon-plus {
    background-position: -408px -96px;
}
.icon-minus {
    background-position: -433px -96px;
}
.icon-asterisk {
    background-position: -456px -96px;
}
.icon-exclamation-sign {
    background-position: 0 -120px;
}
.icon-gift {
    background-position: -24px -120px;
}
.icon-leaf {
    background-position: -48px -120px;
}
.icon-fire {
    background-position: -72px -120px;
}
.icon-eye-open {
    background-position: -96px -120px;
}
.icon-eye-close {
    background-position: -120px -120px;
}
.icon-warning-sign {
    background-position: -144px -120px;
}
.icon-plane {
    background-position: -168px -120px;
}
.icon-calendar {
    background-position: -192px -120px;
}
.icon-random {
    background-position: -216px -120px;
}
.icon-comment {
    background-position: -240px -120px;
}
.icon-magnet {
    background-position: -264px -120px;
}
.icon-chevron-up {
    background-position: -288px -120px;
}
.icon-chevron-down {
    background-position: -313px -119px;
}
.icon-retweet {
    background-position: -336px -120px;
}
.icon-shopping-cart {
    background-position: -360px -120px;
}
.icon-folder-close {
    background-position: -384px -120px;
}
.icon-folder-open {
    background-position: -408px -120px;
}
.icon-resize-vertical {
    background-position: -432px -119px;
}
.icon-resize-horizontal {
    background-position: -456px -118px;
}
.icon-hdd {
    background-position: 0 -144px;
}
.icon-bullhorn {
    background-position: -24px -144px;
}
.icon-bell {
    background-position: -48px -144px;
}
.icon-certificate {
    background-position: -72px -144px;
}
.icon-thumbs-up {
    background-position: -96px -144px;
}
.icon-thumbs-down {
    background-position: -120px -144px;
}
.icon-hand-right {
    background-position: -144px -144px;
}
.icon-hand-left {
    background-position: -168px -144px;
}
.icon-hand-up {
    background-position: -192px -144px;
}
.icon-hand-down {
    background-position: -216px -144px;
}
.icon-circle-arrow-right {
    background-position: -240px -144px;
}
.icon-circle-arrow-left {
    background-position: -264px -144px;
}
.icon-circle-arrow-up {
    background-position: -288px -144px;
}
.icon-circle-arrow-down {
    background-position: -312px -144px;
}
.icon-globe {
    background-position: -336px -144px;
}
.icon-wrench {
    background-position: -360px -144px;
}
.icon-tasks {
    background-position: -384px -144px;
}
.icon-filter {
    background-position: -408px -144px;
}
.icon-briefcase {
    background-position: -432px -144px;
}
.icon-fullscreen {
    background-position: -456px -144px;
}
.dropup,
.dropdown {
    position: relative;
}
.dropdown-toggle {
    *margin-bottom: -3px;
}
.dropdown-toggle:active,
.open .dropdown-toggle {
    outline: 0;
}
.caret {
    display: inline-block;
    width: 0;
    height: 0;
    vertical-align: top;
    border-top: 4px solid #000000;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    content: "";
    opacity: 0.3;
    filter: alpha(opacity=30);
}
.dropdown .caret {
    margin-top: 8px;
    margin-left: 2px;
}
.dropdown:hover .caret,
.open .caret {
    opacity: 1;
    filter: alpha(opacity=100);
}
.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 4px 0;
    margin: 1px 0 0;
    list-style: none;
    background-color: #ffffff;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.2);
    *border-right-width: 2px;
    *border-bottom-width: 2px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
}
.dropdown-menu.pull-right {
    right: 0;
    left: auto;
}
.dropdown-menu .divider {
    *width: 100%;
    height: 1px;
    margin: 8px 1px;
    *margin: -5px 0 5px;
    overflow: hidden;
    background-color: #e5e5e5;
    border-bottom: 1px solid #ffffff;
}
.dropdown-menu a {
    display: block;
    padding: 3px 15px;
    clear: both;
    font-weight: normal;
    line-height: 18px;
    color: #333333;
    white-space: nowrap;
}
.dropdown-menu li > a:hover,
.dropdown-menu .active > a,
.dropdown-menu .active > a:hover {
    color: #ffffff;
    text-decoration: none;
    background-color: #0088cc;
}
.open {
    *z-index: 1000;
}
.open .dropdown-menu {
    display: block;
}
.pull-right .dropdown-menu {
    right: 0;
    left: auto;
}
.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
    border-top: 0;
    border-bottom: 4px solid #000000;
    content: "\2191";
}
.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-bottom: 1px;
}
.typeahead {
    margin-top: 2px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}
.utopia {
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: #ffffff;
    border: 1px solid #eee;
    border: 1px solid rgba(0, 0, 0, 0.05);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    -webkit-box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.03) inset;
    -moz-box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.03) inset;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.03) inset;
}
.utopia blockquote {
    border-color: #ddd;
    border-color: rgba(0, 0, 0, 0.15);
}
.utopia-large {
    padding: 24px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
}
.utopia-small {
    padding: 9px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}
.fade {
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: opacity 0.15s linear;
    -moz-transition: opacity 0.15s linear;
    -ms-transition: opacity 0.15s linear;
    -o-transition: opacity 0.15s linear;
    transition: opacity 0.15s linear;
}
.fade.in {
    opacity: 1;
    filter: alpha(opacity=100);
}
.collapse {
    position: relative;
    height: 0;
    overflow: hidden;
    -webkit-transition: height 0.35s ease;
    -moz-transition: height 0.35s ease;
    -ms-transition: height 0.35s ease;
    -o-transition: height 0.35s ease;
    transition: height 0.35s ease;
}
.collapse.in {
    height: auto;
}
.close {
    float: right;
    font-size: 20px;
    font-weight: bold;
    line-height: 18px;
    color: #000000;
    text-shadow: 0 1px 0 #ffffff;
    opacity: 0.2;
    filter: alpha(opacity=20);
}
.close:hover {
    color: #000000;
    text-decoration: none;
    cursor: pointer;
    opacity: 0.4;
    filter: alpha(opacity=40);
}
button.close {
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
}
.btn {
    display: inline-block;
    *display: inline;
    /* IE7 inline-block hack */

    *zoom: 1;
    padding: 4px 10px 4px;
    margin-bottom: 0;
    font-size: 13px;
    line-height: 18px;
    *line-height: 20px;
    color: #333333;
    text-align: center;
    text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
    vertical-align: middle;
    cursor: pointer;
    background-color: #f5f5f5;
    background-image: -moz-linear-gradient(top, #ffffff, #e6e6e6);
    background-image: -ms-linear-gradient(top, #ffffff, #e6e6e6);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffffff), to(#e6e6e6));
    background-image: -webkit-linear-gradient(top, #ffffff, #e6e6e6);
    background-image: -o-linear-gradient(top, #ffffff, #e6e6e6);
    background-image: linear-gradient(top, #ffffff, #e6e6e6);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#e6e6e6', GradientType=0);
    border-color: #e6e6e6 #e6e6e6 #bfbfbf;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    *background-color: #e6e6e6;
    /* Darken IE7 buttons by default so they stand out more given they won't have borders */

    filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
    border: 1px solid #cccccc;
    *border: 0;
    border-bottom-color: #b3b3b3;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    *margin-left: .3em;
    -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
    -moz-box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
    box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
}
.btn:hover,
.btn:active,
.btn.active,
.btn.disabled,
.btn[disabled] {
    background-color: #e6e6e6;
    *background-color: #d9d9d9;
}
.btn:active,
.btn.active {
    background-color: #cccccc \9;
}
.btn:first-child {
    *margin-left: 0;
}
.btn:hover {
    color: #333333;
    text-decoration: none;
    background-color: #e6e6e6;
    *background-color: #d9d9d9;
    /* Buttons in IE7 don't get borders, so darken on hover */

    background-position: 0 -15px;
    -webkit-transition: background-position 0.1s linear;
    -moz-transition: background-position 0.1s linear;
    -ms-transition: background-position 0.1s linear;
    -o-transition: background-position 0.1s linear;
    transition: background-position 0.1s linear;
}
.btn:focus {
    outline: thin dotted #333;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}
.btn.active,
.btn:active {
    background-color: #e6e6e6;
    background-color: #d9d9d9 \9;
    background-image: none;
    outline: 0;
    -webkit-box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
    -moz-box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
    box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
}
.btn.disabled,
.btn[disabled] {
    cursor: default;
    background-color: #e6e6e6;
    background-image: none;
    opacity: 0.65;
    filter: alpha(opacity=65);
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}
.btn-large {
    padding: 9px 14px;
    font-size: 15px;
    line-height: normal;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}
.btn-large [class^="icon-"] {
    margin-top: 1px;
}
.btn-small {
    padding: 5px 9px;
    font-size: 11px;
    line-height: 16px;
}
.btn-small [class^="icon-"] {
    margin-top: -1px;
}
.btn-mini {
    padding: 2px 6px;
    font-size: 11px;
    line-height: 14px;
}
.btn-primary,
.btn-primary:hover,
.btn-warning,
.btn-warning:hover,
.btn-danger,
.btn-danger:hover,
.btn-success,
.btn-success:hover,
.btn-info,
.btn-info:hover,
.btn-inverse,
.btn-inverse:hover {
    color: #ffffff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.btn-primary.active,
.btn-warning.active,
.btn-danger.active,
.btn-success.active,
.btn-info.active,
.btn-inverse.active {
    color: rgba(255, 255, 255, 0.75);
}
.btn {
    border-color: #ccc;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
}
.btn-primary {
    background-color: #0074cc;
    background-image: -moz-linear-gradient(top, #0088cc, #0055cc);
    background-image: -ms-linear-gradient(top, #0088cc, #0055cc);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#0088cc), to(#0055cc));
    background-image: -webkit-linear-gradient(top, #0088cc, #0055cc);
    background-image: -o-linear-gradient(top, #0088cc, #0055cc);
    background-image: linear-gradient(top, #0088cc, #0055cc);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0088cc', endColorstr='#0055cc', GradientType=0);
    border-color: #0055cc #0055cc #003580;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    *background-color: #0055cc;
    /* Darken IE7 buttons by default so they stand out more given they won't have borders */

    filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.btn-primary:hover,
.btn-primary:active,
.btn-primary.active,
.btn-primary.disabled,
.btn-primary[disabled] {
    background-color: #0055cc;
    *background-color: #004ab3;
}
.btn-primary:active,
.btn-primary.active {
    background-color: #004099 \9;
}
.btn-warning {
    background-color: #faa732;
    background-image: -moz-linear-gradient(top, #fbb450, #f89406);
    background-image: -ms-linear-gradient(top, #fbb450, #f89406);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fbb450), to(#f89406));
    background-image: -webkit-linear-gradient(top, #fbb450, #f89406);
    background-image: -o-linear-gradient(top, #fbb450, #f89406);
    background-image: linear-gradient(top, #fbb450, #f89406);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fbb450', endColorstr='#f89406', GradientType=0);
    border-color: #f89406 #f89406 #ad6704;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    *background-color: #f89406;
    /* Darken IE7 buttons by default so they stand out more given they won't have borders */

    filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.btn-warning:hover,
.btn-warning:active,
.btn-warning.active,
.btn-warning.disabled,
.btn-warning[disabled] {
    background-color: #f89406;
    *background-color: #df8505;
}
.btn-warning:active,
.btn-warning.active {
    background-color: #c67605 \9;
}
.btn-danger {
    background-color: #da4f49;
    background-image: -moz-linear-gradient(top, #ee5f5b, #bd362f);
    background-image: -ms-linear-gradient(top, #ee5f5b, #bd362f);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ee5f5b), to(#bd362f));
    background-image: -webkit-linear-gradient(top, #ee5f5b, #bd362f);
    background-image: -o-linear-gradient(top, #ee5f5b, #bd362f);
    background-image: linear-gradient(top, #ee5f5b, #bd362f);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ee5f5b', endColorstr='#bd362f', GradientType=0);
    border-color: #bd362f #bd362f #802420;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    *background-color: #bd362f;
    /* Darken IE7 buttons by default so they stand out more given they won't have borders */

    filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.btn-danger:hover,
.btn-danger:active,
.btn-danger.active,
.btn-danger.disabled,
.btn-danger[disabled] {
    background-color: #bd362f;
    *background-color: #a9302a;
}
.btn-danger:active,
.btn-danger.active {
    background-color: #942a25 \9;
}
.btn-success {
    background-color: #5bb75b;
    background-image: -moz-linear-gradient(top, #62c462, #51a351);
    background-image: -ms-linear-gradient(top, #62c462, #51a351);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#62c462), to(#51a351));
    background-image: -webkit-linear-gradient(top, #62c462, #51a351);
    background-image: -o-linear-gradient(top, #62c462, #51a351);
    background-image: linear-gradient(top, #62c462, #51a351);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#62c462', endColorstr='#51a351', GradientType=0);
    border-color: #51a351 #51a351 #387038;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    *background-color: #51a351;
    /* Darken IE7 buttons by default so they stand out more given they won't have borders */

    filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.btn-success:hover,
.btn-success:active,
.btn-success.active,
.btn-success.disabled,
.btn-success[disabled] {
    background-color: #51a351;
    *background-color: #499249;
}
.btn-success:active,
.btn-success.active {
    background-color: #408140 \9;
}
.btn-info {
    background-color: #49afcd;
    background-image: -moz-linear-gradient(top, #5bc0de, #2f96b4);
    background-image: -ms-linear-gradient(top, #5bc0de, #2f96b4);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#5bc0de), to(#2f96b4));
    background-image: -webkit-linear-gradient(top, #5bc0de, #2f96b4);
    background-image: -o-linear-gradient(top, #5bc0de, #2f96b4);
    background-image: linear-gradient(top, #5bc0de, #2f96b4);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5bc0de', endColorstr='#2f96b4', GradientType=0);
    border-color: #2f96b4 #2f96b4 #1f6377;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    *background-color: #2f96b4;
    /* Darken IE7 buttons by default so they stand out more given they won't have borders */

    filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.btn-info:hover,
.btn-info:active,
.btn-info.active,
.btn-info.disabled,
.btn-info[disabled] {
    background-color: #2f96b4;
    *background-color: #2a85a0;
}
.btn-info:active,
.btn-info.active {
    background-color: #24748c \9;
}
.btn-inverse {
    background-color: #414141;
    background-image: -moz-linear-gradient(top, #555555, #222222);
    background-image: -ms-linear-gradient(top, #555555, #222222);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#555555), to(#222222));
    background-image: -webkit-linear-gradient(top, #555555, #222222);
    background-image: -o-linear-gradient(top, #555555, #222222);
    background-image: linear-gradient(top, #555555, #222222);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#555555', endColorstr='#222222', GradientType=0);
    border-color: #222222 #222222 #000000;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    *background-color: #222222;
    /* Darken IE7 buttons by default so they stand out more given they won't have borders */

    filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.btn-inverse:hover,
.btn-inverse:active,
.btn-inverse.active,
.btn-inverse.disabled,
.btn-inverse[disabled] {
    background-color: #222222;
    *background-color: #151515;
}
.btn-inverse:active,
.btn-inverse.active {
    background-color: #080808 \9;
}
button.btn,
input[type="submit"].btn {
    *padding-top: 2px;
    *padding-bottom: 2px;
}
button.btn::-moz-focus-inner,
input[type="submit"].btn::-moz-focus-inner {
    padding: 0;
    border: 0;
}
button.btn.btn-large,
input[type="submit"].btn.btn-large {
    *padding-top: 7px;
    *padding-bottom: 7px;
}
button.btn.btn-small,
input[type="submit"].btn.btn-small {
    *padding-top: 3px;
    *padding-bottom: 3px;
}
button.btn.btn-mini,
input[type="submit"].btn.btn-mini {
    *padding-top: 1px;
    *padding-bottom: 1px;
}
.btn-group {
    position: relative;
    *zoom: 1;
    *margin-left: .3em;
}
.btn-group:before,
.btn-group:after {
    display: table;
    content: "";
}
.btn-group:after {
    clear: both;
}
.btn-group:first-child {
    *margin-left: 0;
}
.btn-group + .btn-group {
    margin-left: 5px;
}
.btn-toolbar {
    margin-top: 9px;
    margin-bottom: 9px;
}
.btn-toolbar .btn-group {
    display: inline-block;
    *display: inline;
    /* IE7 inline-block hack */

    *zoom: 1;
}
.btn-group > .btn {
    position: relative;
    float: left;
    margin-left: -1px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}
.btn-group > .btn:first-child {
    margin-left: 0;
    -webkit-border-top-left-radius: 4px;
    -moz-border-radius-topleft: 4px;
    border-top-left-radius: 4px;
    -webkit-border-bottom-left-radius: 4px;
    -moz-border-radius-bottomleft: 4px;
    border-bottom-left-radius: 4px;
}
.btn-group > .btn:last-child,
.btn-group > .dropdown-toggle {
    -webkit-border-top-right-radius: 4px;
    -moz-border-radius-topright: 4px;
    border-top-right-radius: 4px;
    -webkit-border-bottom-right-radius: 4px;
    -moz-border-radius-bottomright: 4px;
    border-bottom-right-radius: 4px;
}
.btn-group > .btn.large:first-child {
    margin-left: 0;
    -webkit-border-top-left-radius: 6px;
    -moz-border-radius-topleft: 6px;
    border-top-left-radius: 6px;
    -webkit-border-bottom-left-radius: 6px;
    -moz-border-radius-bottomleft: 6px;
    border-bottom-left-radius: 6px;
}
.btn-group > .btn.large:last-child,
.btn-group > .large.dropdown-toggle {
    -webkit-border-top-right-radius: 6px;
    -moz-border-radius-topright: 6px;
    border-top-right-radius: 6px;
    -webkit-border-bottom-right-radius: 6px;
    -moz-border-radius-bottomright: 6px;
    border-bottom-right-radius: 6px;
}
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active {
    z-index: 2;
}
.btn-group .dropdown-toggle:active,
.btn-group.open .dropdown-toggle {
    outline: 0;
}
.btn-group > .dropdown-toggle {
    padding-left: 8px;
    padding-right: 8px;
    -webkit-box-shadow: inset 1px 0 0 rgba(255,255,255,.125), inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
    -moz-box-shadow: inset 1px 0 0 rgba(255,255,255,.125), inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
    box-shadow: inset 1px 0 0 rgba(255,255,255,.125), inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
    *padding-top: 4px;
    *padding-bottom: 4px;
}
.btn-group > .btn-mini.dropdown-toggle {
    padding-left: 5px;
    padding-right: 5px;
}
.btn-group > .btn-small.dropdown-toggle {
    *padding-top: 4px;
    *padding-bottom: 4px;
}
.btn-group > .btn-large.dropdown-toggle {
    padding-left: 12px;
    padding-right: 12px;
}
.btn-group.open .dropdown-toggle {
    background-image: none;
    -webkit-box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
    -moz-box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
    box-shadow: inset 0 2px 4px rgba(0,0,0,.15), 0 1px 2px rgba(0,0,0,.05);
}
.btn-group.open .btn.dropdown-toggle {
    background-color: #e6e6e6;
}
.btn-group.open .btn-primary.dropdown-toggle {
    background-color: #0055cc;
}
.btn-group.open .btn-warning.dropdown-toggle {
    background-color: #f89406;
}
.btn-group.open .btn-danger.dropdown-toggle {
    background-color: #bd362f;
}
.btn-group.open .btn-success.dropdown-toggle {
    background-color: #51a351;
}
.btn-group.open .btn-info.dropdown-toggle {
    background-color: #2f96b4;
}
.btn-group.open .btn-inverse.dropdown-toggle {
    background-color: #222222;
}
.btn .caret {
    margin-top: 7px;
    margin-left: 0;
}
.btn:hover .caret,
.open.btn-group .caret {
    opacity: 1;
    filter: alpha(opacity=100);
}
.btn-mini .caret {
    margin-top: 5px;
}
.btn-small .caret {
    margin-top: 6px;
}
.btn-large .caret {
    margin-top: 6px;
    border-left-width: 5px;
    border-right-width: 5px;
    border-top-width: 5px;
}
.dropup .btn-large .caret {
    border-bottom: 5px solid #000000;
    border-top: 0;
}
.btn-primary .caret,
.btn-warning .caret,
.btn-danger .caret,
.btn-info .caret,
.btn-success .caret,
.btn-inverse .caret {
    border-top-color: #ffffff;
    border-bottom-color: #ffffff;
    opacity: 0.75;
    filter: alpha(opacity=75);
}
.alert {
    padding: 8px 35px 8px 14px;
    margin-bottom: 18px;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
    background-color: #fcf8e3;
    border: 1px solid #fbeed5;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    color: #c09853;
}
.alert-heading {
    color: inherit;
}
.alert .close {
    position: relative;
    top: -2px;
    right: -21px;
    line-height: 18px;
}
.alert-success {
    background-color: #dff0d8;
    border-color: #d6e9c6;
    color: #468847;
}
.alert-danger,
.alert-error {
    background-color: #f2dede;
    border-color: #eed3d7;
    color: #b94a48;
}
.alert-info {
    background-color: #d9edf7;
    border-color: #bce8f1;
    color: #3a87ad;
}
.alert-block {
    padding-top: 14px;
    padding-bottom: 14px;
}
.alert-block > p,
.alert-block > ul {
    margin-bottom: 0;
}
.alert-block p + p {
    margin-top: 5px;
}
.nav {
    margin-left: 0;
    margin-bottom: 18px;
    list-style: none;
}
.nav > li > a {
    display: block;
}
.nav > li > a:hover {
    text-decoration: none;
    background-color: #eeeeee;
}
.nav > .pull-right {
    float: right;
}
.nav .nav-header {
    display: block;
    padding: 3px 15px;
    font-size: 11px;
    font-weight: bold;
    line-height: 18px;
    color: #999999;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
    text-transform: uppercase;
}
.nav li + .nav-header {
    margin-top: 9px;
}
.nav-list {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 0;
}
.nav-list > li > a,
.nav-list .nav-header {
    margin-left: -15px;
    margin-right: -15px;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
}
.nav-list > li > a {
    padding: 3px 15px;
}
.nav-list > .active > a,
.nav-list > .active > a:hover {
    color: #ffffff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
    background-color: #0088cc;
}
.nav-list [class^="icon-"] {
    margin-right: 2px;
}
.nav-list .divider {
    *width: 100%;
    height: 1px;
    margin: 8px 1px;
    *margin: -5px 0 5px;
    overflow: hidden;
    background-color: #e5e5e5;
    border-bottom: 1px solid #ffffff;
}
.nav-tabs,
.nav-pills {
    *zoom: 1;
}
.nav-tabs:before,
.nav-pills:before,
.nav-tabs:after,
.nav-pills:after {
    display: table;
    content: "";
}
.nav-tabs:after,
.nav-pills:after {
    clear: both;
}
.nav-tabs > li,
.nav-pills > li {
    float: left;
}
.nav-tabs > li > a,
.nav-pills > li > a {
    padding-right: 12px;
    padding-left: 12px;
    margin-right: 2px;
    line-height: 14px;
}
.nav-tabs {
    border-bottom: 1px solid #ddd;
}
.nav-tabs > li {
    margin-bottom: -1px;
}
.nav-tabs > li > a {
    padding-top: 8px;
    padding-bottom: 8px;
    line-height: 18px;
    border: 1px solid transparent;
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
}
.nav-tabs > li > a:hover {
    border-color: #eeeeee #eeeeee #dddddd;
}
.nav-tabs > .active > a,
.nav-tabs > .active > a:hover {
    color: #555555;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-bottom-color: transparent;
    cursor: default;
}
.nav-pills > li > a {
    padding-top: 8px;
    padding-bottom: 8px;
    margin-top: 2px;
    margin-bottom: 2px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}
.nav-pills > .active > a,
.nav-pills > .active > a:hover {
    color: #ffffff;
    background-color: #0088cc;
}
.nav-stacked > li {
    float: none;
}
.nav-stacked > li > a {
    margin-right: 0;
}
.nav-tabs.nav-stacked {
    border-bottom: 0;
}
.nav-tabs.nav-stacked > li > a {
    border: 1px solid #ddd;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}
.nav-tabs.nav-stacked > li:first-child > a {
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
}
.nav-tabs.nav-stacked > li:last-child > a {
    -webkit-border-radius: 0 0 4px 4px;
    -moz-border-radius: 0 0 4px 4px;
    border-radius: 0 0 4px 4px;
}
.nav-tabs.nav-stacked > li > a:hover {
    border-color: #ddd;
    z-index: 2;
}
.nav-pills.nav-stacked > li > a {
    margin-bottom: 3px;
}
.nav-pills.nav-stacked > li:last-child > a {
    margin-bottom: 1px;
}
.nav-tabs .dropdown-menu {
    -webkit-border-radius: 0 0 5px 5px;
    -moz-border-radius: 0 0 5px 5px;
    border-radius: 0 0 5px 5px;
}
.nav-pills .dropdown-menu {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}
.nav-tabs .dropdown-toggle .caret,
.nav-pills .dropdown-toggle .caret {
    border-top-color: #0088cc;
    border-bottom-color: #0088cc;
    margin-top: 6px;
}
.nav-tabs .dropdown-toggle:hover .caret,
.nav-pills .dropdown-toggle:hover .caret {
    border-top-color: #005580;
    border-bottom-color: #005580;
}
.nav-tabs .active .dropdown-toggle .caret,
.nav-pills .active .dropdown-toggle .caret {
    border-top-color: #333333;
    border-bottom-color: #333333;
}
.nav > .dropdown.active > a:hover {
    color: #000000;
    cursor: pointer;
}
.nav-tabs .open .dropdown-toggle,
.nav-pills .open .dropdown-toggle,
.nav > li.dropdown.open.active > a:hover {
    color: #ffffff;
    background-color: #999999;
    border-color: #999999;
}
.nav li.dropdown.open .caret,
.nav li.dropdown.open.active .caret,
.nav li.dropdown.open a:hover .caret {
    border-top-color: #ffffff;
    border-bottom-color: #ffffff;
    opacity: 1;
    filter: alpha(opacity=100);
}
.tabs-stacked .open > a:hover {
    border-color: #999999;
}
.tabbable {
    *zoom: 1;
}
.tabbable:before,
.tabbable:after {
    display: table;
    content: "";
}
.tabbable:after {
    clear: both;
}
.tab-content {
    overflow: auto;
}
.tabs-below > .nav-tabs,
.tabs-right > .nav-tabs,
.tabs-left > .nav-tabs {
    border-bottom: 0;
}
.tab-content > .tab-pane,
.pill-content > .pill-pane {
    display: none;
}
.tab-content > .active,
.pill-content > .active {
    display: block;
}
.tabs-below > .nav-tabs {
    border-top: 1px solid #ddd;
}
.tabs-below > .nav-tabs > li {
    margin-top: -1px;
    margin-bottom: 0;
}
.tabs-below > .nav-tabs > li > a {
    -webkit-border-radius: 0 0 4px 4px;
    -moz-border-radius: 0 0 4px 4px;
    border-radius: 0 0 4px 4px;
}
.tabs-below > .nav-tabs > li > a:hover {
    border-bottom-color: transparent;
    border-top-color: #ddd;
}
.tabs-below > .nav-tabs > .active > a,
.tabs-below > .nav-tabs > .active > a:hover {
    border-color: transparent #ddd #ddd #ddd;
}
.tabs-left > .nav-tabs > li,
.tabs-right > .nav-tabs > li {
    float: none;
}
.tabs-left > .nav-tabs > li > a,
.tabs-right > .nav-tabs > li > a {
    min-width: 74px;
    margin-right: 0;
    margin-bottom: 3px;
}
.tabs-left > .nav-tabs {
    float: left;
    margin-right: 19px;
    border-right: 1px solid #ddd;
}
.tabs-left > .nav-tabs > li > a {
    margin-right: -1px;
    -webkit-border-radius: 4px 0 0 4px;
    -moz-border-radius: 4px 0 0 4px;
    border-radius: 4px 0 0 4px;
}
.tabs-left > .nav-tabs > li > a:hover {
    border-color: #eeeeee #dddddd #eeeeee #eeeeee;
}
.tabs-left > .nav-tabs .active > a,
.tabs-left > .nav-tabs .active > a:hover {
    border-color: #ddd transparent #ddd #ddd;
    *border-right-color: #ffffff;
}
.tabs-right > .nav-tabs {
    float: right;
    margin-left: 19px;
    border-left: 1px solid #ddd;
}
.tabs-right > .nav-tabs > li > a {
    margin-left: -1px;
    -webkit-border-radius: 0 4px 4px 0;
    -moz-border-radius: 0 4px 4px 0;
    border-radius: 0 4px 4px 0;
}
.tabs-right > .nav-tabs > li > a:hover {
    border-color: #eeeeee #eeeeee #eeeeee #dddddd;
}
.tabs-right > .nav-tabs .active > a,
.tabs-right > .nav-tabs .active > a:hover {
    border-color: #ddd #ddd #ddd transparent;
    *border-left-color: #ffffff;
}
.navbar {
    *position: relative;
    *z-index: 2;
    overflow: visible;
    margin-bottom: 18px;
}
.navbar-inner {
    min-height: 40px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #2c2c2c;
    background-image: -moz-linear-gradient(top, #333333, #222222);
    background-image: -ms-linear-gradient(top, #333333, #222222);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#333333), to(#222222));
    background-image: -webkit-linear-gradient(top, #333333, #222222);
    background-image: -o-linear-gradient(top, #333333, #222222);
    background-image: linear-gradient(top, #333333, #222222);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#333333', endColorstr='#222222', GradientType=0);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 3px rgba(0,0,0,.25), inset 0 -1px 0 rgba(0,0,0,.1);
    -moz-box-shadow: 0 1px 3px rgba(0,0,0,.25), inset 0 -1px 0 rgba(0,0,0,.1);
    box-shadow: 0 1px 3px rgba(0,0,0,.25), inset 0 -1px 0 rgba(0,0,0,.1);
}
.navbar .container {
    width: auto;
}
.nav-collapse.collapse {
    height: auto;
}
.navbar {
    color: #999999;
}
.navbar .brand:hover {
    text-decoration: none;
}
.navbar .brand {
    float: left;
    display: block;
    padding: 8px 20px 12px;
    margin-left: -20px;
    font-size: 20px;
    font-weight: 200;
    line-height: 1;
    color: #999999;
}
.navbar .navbar-text {
    margin-bottom: 0;
    line-height: 40px;
}
.navbar .navbar-link {
    color: #999999;
}
.navbar .navbar-link:hover {
    color: #ffffff;
}
.navbar .btn,
.navbar .btn-group {
    margin-top: 5px;
}
.navbar .btn-group .btn {
    margin: 0;
}
.navbar-form {
    margin-bottom: 0;
    *zoom: 1;
}
.navbar-form:before,
.navbar-form:after {
    display: table;
    content: "";
}
.navbar-form:after {
    clear: both;
}
.navbar-form input,
.navbar-form select,
.navbar-form .radio,
.navbar-form .checkbox {
    margin-top: 5px;
}
.navbar-form input,
.navbar-form select {
    display: inline-block;
    margin-bottom: 0;
}
.navbar-form input[type="image"],
.navbar-form input[type="checkbox"],
.navbar-form input[type="radio"] {
    margin-top: 3px;
}
.navbar-form .input-append,
.navbar-form .input-prepend {
    margin-top: 6px;
    white-space: nowrap;
}
.navbar-form .input-append input,
.navbar-form .input-prepend input {
    margin-top: 0;
}
.navbar-search {
    position: relative;
    float: left;
    margin-top: 6px;
    margin-bottom: 0;
}
.navbar-search .search-query {
    padding: 4px 9px;
    font-family: "Imprima", Helvetica, Arial, sans-serif;
    font-size: 13px;
    font-weight: normal;
    line-height: 1;
    color: #ffffff;
    background-color: #626262;
    border: 1px solid #151515;
    -webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,.1), 0 1px 0 rgba(255,255,255,.15);
    -moz-box-shadow: inset 0 1px 2px rgba(0,0,0,.1), 0 1px 0 rgba(255,255,255,.15);
    box-shadow: inset 0 1px 2px rgba(0,0,0,.1), 0 1px 0 rgba(255,255,255,.15);
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    transition: none;
}
.navbar-search .search-query:-moz-placeholder {
    color: #cccccc;
}
.navbar-search .search-query::-webkit-input-placeholder {
    color: #cccccc;
}
.navbar-search .search-query:focus,
.navbar-search .search-query.focused {
    padding: 5px 10px;
    color: #333333;
    text-shadow: 0 1px 0 #ffffff;
    background-color: #ffffff;
    border: 0;
    -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
    outline: 0;
}
.navbar-fixed-top,
.navbar-fixed-bottom {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1030;
    margin-bottom: 0;
}
.navbar-fixed-top .navbar-inner,
.navbar-fixed-bottom .navbar-inner {
    padding-left: 0;
    padding-right: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}
.navbar-fixed-top .container,
.navbar-fixed-bottom .container {
    width: 940px;
}
.navbar-fixed-top {
    top: 0;
}
.navbar-fixed-bottom {
    bottom: 0;
}
.navbar .nav {
    position: relative;
    left: 0;
    display: block;
    float: left;
    margin: 0 10px 0 0;
}
.navbar .nav.pull-right {
    float: right;
}
.navbar .nav > li {
    display: block;
    float: left;
}
.navbar .nav > li > a {
    float: none;
    padding: 9px 10px 11px;
    line-height: 19px;
    color: #999999;
    text-decoration: none;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.navbar .btn {
    display: inline-block;
    padding: 4px 10px 4px;
    margin: 5px 5px 6px;
    line-height: 18px;
}
.navbar .btn-group {
    margin: 0;
    padding: 5px 5px 6px;
}
.navbar .nav > li > a:hover {
    background-color: transparent;
    color: #ffffff;
    text-decoration: none;
}
.navbar .nav .active > a,
.navbar .nav .active > a:hover {
    color: #ffffff;
    text-decoration: none;
    background-color: #222222;
}
.navbar .divider-vertical {
    height: 40px;
    width: 1px;
    margin: 0 9px;
    overflow: hidden;
    background-color: #222222;
    border-right: 1px solid #333333;
}
.navbar .nav.pull-right {
    margin-left: 10px;
    margin-right: 0;
}
.navbar .btn-navbar {
    display: none;
    float: right;
    padding: 7px 10px;
    margin-left: 5px;
    margin-right: 5px;
    background-color: #2c2c2c;
    background-image: -moz-linear-gradient(top, #333333, #222222);
    background-image: -ms-linear-gradient(top, #333333, #222222);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#333333), to(#222222));
    background-image: -webkit-linear-gradient(top, #333333, #222222);
    background-image: -o-linear-gradient(top, #333333, #222222);
    background-image: linear-gradient(top, #333333, #222222);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#333333', endColorstr='#222222', GradientType=0);
    border-color: #222222 #222222 #000000;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    *background-color: #222222;
    /* Darken IE7 buttons by default so they stand out more given they won't have borders */

    filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
    -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.1), 0 1px 0 rgba(255,255,255,.075);
    -moz-box-shadow: inset 0 1px 0 rgba(255,255,255,.1), 0 1px 0 rgba(255,255,255,.075);
    box-shadow: inset 0 1px 0 rgba(255,255,255,.1), 0 1px 0 rgba(255,255,255,.075);
}
.navbar .btn-navbar:hover,
.navbar .btn-navbar:active,
.navbar .btn-navbar.active,
.navbar .btn-navbar.disabled,
.navbar .btn-navbar[disabled] {
    background-color: #222222;
    *background-color: #151515;
}
.navbar .btn-navbar:active,
.navbar .btn-navbar.active {
    background-color: #080808 \9;
}
.navbar .btn-navbar .icon-bar {
    display: block;
    width: 18px;
    height: 2px;
    background-color: #f5f5f5;
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;
    border-radius: 1px;
    -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
}
.btn-navbar .icon-bar + .icon-bar {
    margin-top: 3px;
}
.navbar .dropdown-menu:before {
    content: '';
    display: inline-block;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #ccc;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: -7px;
    left: 9px;
}
.navbar .dropdown-menu:after {
    content: '';
    display: inline-block;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #ffffff;
    position: absolute;
    top: -6px;
    left: 10px;
}
.navbar-fixed-bottom .dropdown-menu:before {
    border-top: 7px solid #ccc;
    border-top-color: rgba(0, 0, 0, 0.2);
    border-bottom: 0;
    bottom: -7px;
    top: auto;
}
.navbar-fixed-bottom .dropdown-menu:after {
    border-top: 6px solid #ffffff;
    border-bottom: 0;
    bottom: -6px;
    top: auto;
}
.navbar .nav li.dropdown .dropdown-toggle .caret,
.navbar .nav li.dropdown.open .caret {
    border-top-color: #ffffff;
    border-bottom-color: #ffffff;
}
.navbar .nav li.dropdown.active .caret {
    opacity: 1;
    filter: alpha(opacity=100);
}
.navbar .nav li.dropdown.open > .dropdown-toggle,
.navbar .nav li.dropdown.active > .dropdown-toggle,
.navbar .nav li.dropdown.open.active > .dropdown-toggle {
    background-color: transparent;
}
.navbar .nav li.dropdown.active > .dropdown-toggle:hover {
    color: #ffffff;
}
.navbar .pull-right .dropdown-menu,
.navbar .dropdown-menu.pull-right {
    left: auto;
    right: 0;
}
.navbar .pull-right .dropdown-menu:before,
.navbar .dropdown-menu.pull-right:before {
    left: auto;
    right: 12px;
}
.navbar .pull-right .dropdown-menu:after,
.navbar .dropdown-menu.pull-right:after {
    left: auto;
    right: 13px;
}
.breadcrumb {
    padding: 7px 14px;
    margin: 0 0 18px;
    list-style: none;
    background-color: #fbfbfb;
    background-image: -moz-linear-gradient(top, #ffffff, #f5f5f5);
    background-image: -ms-linear-gradient(top, #ffffff, #f5f5f5);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffffff), to(#f5f5f5));
    background-image: -webkit-linear-gradient(top, #ffffff, #f5f5f5);
    background-image: -o-linear-gradient(top, #ffffff, #f5f5f5);
    background-image: linear-gradient(top, #ffffff, #f5f5f5);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#f5f5f5', GradientType=0);
    border: 1px solid #ddd;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    -webkit-box-shadow: inset 0 1px 0 #ffffff;
    -moz-box-shadow: inset 0 1px 0 #ffffff;
    box-shadow: inset 0 1px 0 #ffffff;
}
.breadcrumb li {
    display: inline-block;
    *display: inline;
    /* IE7 inline-block hack */

    *zoom: 1;
    text-shadow: 0 1px 0 #ffffff;
}
.breadcrumb .divider {
    padding: 0 5px;
    color: #999999;
}
.breadcrumb .active a {
    color: #333333;
}
.pagination {
    height: 36px;
    margin: 18px 0;
}
.pagination ul {
    display: inline-block;
    *display: inline;
    /* IE7 inline-block hack */

    *zoom: 1;
    margin-left: 0;
    margin-bottom: 0;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}
.pagination li {
    display: inline;
}
.pagination a {
    float: left;
    padding: 0 14px;
    line-height: 34px;
    text-decoration: none;
    border: 1px solid #ddd;
    border-left-width: 0;
}
.pagination a:hover,
.pagination .active a {
    background-color: #f5f5f5;
}
.pagination .active a {
    color: #999999;
    cursor: default;
}
.pagination .disabled span,
.pagination .disabled a,
.pagination .disabled a:hover {
    color: #999999;
    background-color: transparent;
    cursor: default;
}
.pagination li:first-child a {
    border-left-width: 1px;
    -webkit-border-radius: 3px 0 0 3px;
    -moz-border-radius: 3px 0 0 3px;
    border-radius: 3px 0 0 3px;
}
.pagination li:last-child a {
    -webkit-border-radius: 0 3px 3px 0;
    -moz-border-radius: 0 3px 3px 0;
    border-radius: 0 3px 3px 0;
}
.pagination-centered {
    text-align: center;
}
.pagination-right {
    text-align: right;
}
.pager {
    margin-left: 0;
    margin-bottom: 18px;
    list-style: none;
    text-align: center;
    *zoom: 1;
}
.pager:before,
.pager:after {
    display: table;
    content: "";
}
.pager:after {
    clear: both;
}
.pager li {
    display: inline;
}
.pager a {
    display: inline-block;
    padding: 5px 14px;
    background-color: #fff;
    border: 1px solid #ddd;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
}
.pager a:hover {
    text-decoration: none;
    background-color: #f5f5f5;
}
.pager .next a {
    float: right;
}
.pager .previous a {
    float: left;
}
.pager .disabled a,
.pager .disabled a:hover {
    color: #999999;
    background-color: #fff;
    cursor: default;
}
.modal-open .dropdown-menu {
    z-index: 2050;
}
.modal-open .dropdown.open {
    *z-index: 2050;
}
.modal-open .popover {
    z-index: 2060;
}
.modal-open .tooltip {
    z-index: 2070;
}
.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #000000;
}
.modal-backdrop.fade {
    opacity: 0;
}
.modal-backdrop,
.modal-backdrop.fade.in {
    opacity: 0.8;
    filter: alpha(opacity=80);
}
.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 1050;
    overflow: auto;
    width: 560px;
    margin: -250px 0 0 -280px;
    background-color: #ffffff;
    border: 1px solid #999;
    border: 1px solid rgba(0, 0, 0, 0.3);
    *border: 1px solid #999;
    /* IE6-7 */

    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding-box;
    background-clip: padding-box;
}
.modal.fade {
    -webkit-transition: opacity .3s linear, top .3s ease-out;
    -moz-transition: opacity .3s linear, top .3s ease-out;
    -ms-transition: opacity .3s linear, top .3s ease-out;
    -o-transition: opacity .3s linear, top .3s ease-out;
    transition: opacity .3s linear, top .3s ease-out;
    top: -25%;
}
.modal.fade.in {
    top: 50%;
}
.modal-header {
    padding: 9px 15px;
    border-bottom: 1px solid #eee;
}
.modal-header .close {
    margin-top: 2px;
}
.modal-body {
    overflow-y: auto;
    max-height: 400px;
    padding: 15px;
}
.modal-form {
    margin-bottom: 0;
}
.modal-footer {
    padding: 14px 15px 15px;
    margin-bottom: 0;
    text-align: right;
    background-color: #f5f5f5;
    border-top: 1px solid #ddd;
    -webkit-border-radius: 0 0 6px 6px;
    -moz-border-radius: 0 0 6px 6px;
    border-radius: 0 0 6px 6px;
    -webkit-box-shadow: inset 0 1px 0 #ffffff;
    -moz-box-shadow: inset 0 1px 0 #ffffff;
    box-shadow: inset 0 1px 0 #ffffff;
    *zoom: 1;
}
.modal-footer:before,
.modal-footer:after {
    display: table;
    content: "";
}
.modal-footer:after {
    clear: both;
}
.modal-footer .btn + .btn {
    margin-left: 5px;
    margin-bottom: 0;
}
.modal-footer .btn-group .btn + .btn {
    margin-left: -1px;
}
.tooltip {
    position: absolute;
    z-index: 1020;
    display: block;
    visibility: visible;
    padding: 5px;
    font-size: 11px;
    opacity: 0;
    filter: alpha(opacity=0);
}
.tooltip.in {
    opacity: 0.8;
    filter: alpha(opacity=80);
}
.tooltip.top {
    margin-top: -2px;
}
.tooltip.right {
    margin-left: 2px;
}
.tooltip.bottom {
    margin-top: 2px;
}
.tooltip.left {
    margin-left: -2px;
}
.tooltip.top .tooltip-arrow {
    bottom: 0;
    left: 50%;
    margin-left: -5px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #000000;
}
.tooltip.left .tooltip-arrow {
    top: 50%;
    right: 0;
    margin-top: -5px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid #000000;
}
.tooltip.bottom .tooltip-arrow {
    top: 0;
    left: 50%;
    margin-left: -5px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #000000;
}
.tooltip.right .tooltip-arrow {
    top: 50%;
    left: 0;
    margin-top: -5px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 5px solid #000000;
}
.tooltip-inner {
    max-width: 200px;
    padding: 3px 8px;
    color: #ffffff;
    text-align: center;
    text-decoration: none;
    background-color: #000000;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}
.tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
}
.popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1010;
    display: none;
    padding: 5px;
}
.popover.top {
    margin-top: -5px;
}
.popover.right {
    margin-left: 5px;
}
.popover.bottom {
    margin-top: 5px;
}
.popover.left {
    margin-left: -5px;
}
.popover.top .arrow {
    bottom: 0;
    left: 50%;
    margin-left: -5px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #000000;
}
.popover.right .arrow {
    top: 50%;
    left: 0;
    margin-top: -5px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 5px solid #000000;
}
.popover.bottom .arrow {
    top: 0;
    left: 50%;
    margin-left: -5px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #000000;
}
.popover.left .arrow {
    top: 50%;
    right: 0;
    margin-top: -5px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid #000000;
}
.popover .arrow {
    position: absolute;
    width: 0;
    height: 0;
}
.popover-inner {
    padding: 3px;
    width: 280px;
    overflow: hidden;
    background: #000000;
    background: rgba(0, 0, 0, 0.8);
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
}
.popover-title {
    padding: 9px 15px;
    line-height: 1;
    background-color: #f5f5f5;
    border-bottom: 1px solid #eee;
    -webkit-border-radius: 3px 3px 0 0;
    -moz-border-radius: 3px 3px 0 0;
    border-radius: 3px 3px 0 0;
}
.popover-content {
    padding: 14px;
    background-color: #ffffff;
    -webkit-border-radius: 0 0 3px 3px;
    -moz-border-radius: 0 0 3px 3px;
    border-radius: 0 0 3px 3px;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding-box;
    background-clip: padding-box;
}
.popover-content p,
.popover-content ul,
.popover-content ol {
    margin-bottom: 0;
}
.thumbnails {
    margin-left: -20px;
    list-style: none;
    *zoom: 1;
}
.thumbnails:before,
.thumbnails:after {
    display: table;
    content: "";
}
.thumbnails:after {
    clear: both;
}
.row-fluid .thumbnails {
    margin-left: 0;
}
.thumbnails > li {
    float: left;
    margin-bottom: 18px;
    margin-left: 20px;
}
.thumbnail {
    display: block;
    padding: 4px;
    line-height: 1;
    border: 1px solid #ddd;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
    -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
}
a.thumbnail:hover {
    border-color: #0088cc;
    -webkit-box-shadow: 0 1px 4px rgba(0, 105, 214, 0.25);
    -moz-box-shadow: 0 1px 4px rgba(0, 105, 214, 0.25);
    box-shadow: 0 1px 4px rgba(0, 105, 214, 0.25);
}
.thumbnail > img {
    display: block;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
}
.thumbnail .caption {
    padding: 9px;
}
.label,
.badge {
    font-size: 10.998px;
    font-weight: bold;
    line-height: 14px;
    color: #ffffff;
    vertical-align: baseline;
    white-space: nowrap;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    background-color: #999999;
}
.label {
    padding: 1px 4px 2px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}
.badge {
    padding: 1px 9px 2px;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    border-radius: 9px;
}
a.label:hover,
a.badge:hover {
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
}
.label-important,
.badge-important {
    background-color: #b94a48;
}
.label-important[href],
.badge-important[href] {
    background-color: #953b39;
}
.label-warning,
.badge-warning {
    background-color: #f89406;
}
.label-warning[href],
.badge-warning[href] {
    background-color: #c67605;
}
.label-success,
.badge-success {
    background-color: #468847;
}
.label-success[href],
.badge-success[href] {
    background-color: #356635;
}
.label-info,
.badge-info {
    background-color: #3a87ad;
}
.label-info[href],
.badge-info[href] {
    background-color: #2d6987;
}
.label-inverse,
.badge-inverse {
    background-color: #333333;
}
.label-inverse[href],
.badge-inverse[href] {
    background-color: #1a1a1a;
}
@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
@-moz-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
@-ms-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
@-o-keyframes progress-bar-stripes {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 40px 0;
  }
}
@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
    overflow: hidden;
    height: 18px;
    margin-bottom: 18px;
    background-color: #f7f7f7;
    background-image: -moz-linear-gradient(top, #f5f5f5, #f9f9f9);
    background-image: -ms-linear-gradient(top, #f5f5f5, #f9f9f9);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f5f5f5), to(#f9f9f9));
    background-image: -webkit-linear-gradient(top, #f5f5f5, #f9f9f9);
    background-image: -o-linear-gradient(top, #f5f5f5, #f9f9f9);
    background-image: linear-gradient(top, #f5f5f5, #f9f9f9);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f5f5f5', endColorstr='#f9f9f9', GradientType=0);
    -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}
.progress .bar {
    width: 0%;
    height: 18px;
    color: #ffffff;
    font-size: 12px;
    text-align: center;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    background-color: #0e90d2;
    background-image: -moz-linear-gradient(top, #149bdf, #0480be);
    background-image: -ms-linear-gradient(top, #149bdf, #0480be);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#149bdf), to(#0480be));
    background-image: -webkit-linear-gradient(top, #149bdf, #0480be);
    background-image: -o-linear-gradient(top, #149bdf, #0480be);
    background-image: linear-gradient(top, #149bdf, #0480be);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#149bdf', endColorstr='#0480be', GradientType=0);
    -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: width 0.6s ease;
    -moz-transition: width 0.6s ease;
    -ms-transition: width 0.6s ease;
    -o-transition: width 0.6s ease;
    transition: width 0.6s ease;
}
.progress-striped .bar {
    background-color: #149bdf;
    background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
    background-image: -webkit-linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -moz-linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -ms-linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    -webkit-background-size: 40px 40px;
    -moz-background-size: 40px 40px;
    -o-background-size: 40px 40px;
    background-size: 40px 40px;
}
.progress.active .bar {
    -webkit-animation: progress-bar-stripes 2s linear infinite;
    -moz-animation: progress-bar-stripes 2s linear infinite;
    -ms-animation: progress-bar-stripes 2s linear infinite;
    -o-animation: progress-bar-stripes 2s linear infinite;
    animation: progress-bar-stripes 2s linear infinite;
}
.progress-danger .bar {
    background-color: #dd514c;
    background-image: -moz-linear-gradient(top, #ee5f5b, #c43c35);
    background-image: -ms-linear-gradient(top, #ee5f5b, #c43c35);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ee5f5b), to(#c43c35));
    background-image: -webkit-linear-gradient(top, #ee5f5b, #c43c35);
    background-image: -o-linear-gradient(top, #ee5f5b, #c43c35);
    background-image: linear-gradient(top, #ee5f5b, #c43c35);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ee5f5b', endColorstr='#c43c35', GradientType=0);
}
.progress-danger.progress-striped .bar {
    background-color: #ee5f5b;
    background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
    background-image: -webkit-linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -moz-linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -ms-linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-success .bar {
    background-color: #5eb95e;
    background-image: -moz-linear-gradient(top, #62c462, #57a957);
    background-image: -ms-linear-gradient(top, #62c462, #57a957);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#62c462), to(#57a957));
    background-image: -webkit-linear-gradient(top, #62c462, #57a957);
    background-image: -o-linear-gradient(top, #62c462, #57a957);
    background-image: linear-gradient(top, #62c462, #57a957);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#62c462', endColorstr='#57a957', GradientType=0);
}
.progress-success.progress-striped .bar {
    background-color: #62c462;
    background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
    background-image: -webkit-linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -moz-linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -ms-linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-info .bar {
    background-color: #4bb1cf;
    background-image: -moz-linear-gradient(top, #5bc0de, #339bb9);
    background-image: -ms-linear-gradient(top, #5bc0de, #339bb9);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#5bc0de), to(#339bb9));
    background-image: -webkit-linear-gradient(top, #5bc0de, #339bb9);
    background-image: -o-linear-gradient(top, #5bc0de, #339bb9);
    background-image: linear-gradient(top, #5bc0de, #339bb9);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5bc0de', endColorstr='#339bb9', GradientType=0);
}
.progress-info.progress-striped .bar {
    background-color: #5bc0de;
    background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
    background-image: -webkit-linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -moz-linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -ms-linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-warning .bar {
    background-color: #faa732;
    background-image: -moz-linear-gradient(top, #fbb450, #f89406);
    background-image: -ms-linear-gradient(top, #fbb450, #f89406);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fbb450), to(#f89406));
    background-image: -webkit-linear-gradient(top, #fbb450, #f89406);
    background-image: -o-linear-gradient(top, #fbb450, #f89406);
    background-image: linear-gradient(top, #fbb450, #f89406);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fbb450', endColorstr='#f89406', GradientType=0);
}
.progress-warning.progress-striped .bar {
    background-color: #fbb450;
    background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
    background-image: -webkit-linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -moz-linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -ms-linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.accordion {
    margin-bottom: 18px;
}
.accordion-group {
    margin-bottom: 2px;
    border: 1px solid #e5e5e5;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}
.accordion-heading {
    border-bottom: 0;
}
.accordion-heading .accordion-toggle {
    display: block;
    padding: 8px 15px;
}
.accordion-toggle {
    cursor: pointer;
}
.accordion-inner {
    padding: 9px 15px;
    border-top: 1px solid #e5e5e5;
}
.carousel {
    position: relative;
    margin-bottom: 18px;
    line-height: 1;
}
.carousel-inner {
    overflow: hidden;
    width: 100%;
    position: relative;
}
.carousel .item {
    display: none;
    position: relative;
    -webkit-transition: 0.6s ease-in-out left;
    -moz-transition: 0.6s ease-in-out left;
    -ms-transition: 0.6s ease-in-out left;
    -o-transition: 0.6s ease-in-out left;
    transition: 0.6s ease-in-out left;
}
.carousel .item > img {
    display: block;
    line-height: 1;


}
.carousel .active,
.carousel .next,
.carousel .prev {
    display: block;
}
.carousel .active {
    left: 0;
}
.carousel .next,
.carousel .prev {
    position: absolute;
    top: 0;
    width: 100%;
}
.carousel .next {
    left: 100%;
}
.carousel .prev {
    left: -100%;
}
.carousel .next.left,
.carousel .prev.right {
    left: 0;
}
.carousel .active.left {
    left: -100%;
}
.carousel .active.right {
    left: 100%;
}
.carousel-control {
    position: absolute;
    top: 40%;
    left: 15px;
    width: 40px;
    height: 40px;
    margin-top: -20px;
    font-size: 60px;
    font-weight: 100;
    line-height: 30px;
    color: #ffffff;
    text-align: center;
    background: #222222;
    border: 3px solid #ffffff;
    -webkit-border-radius: 23px;
    -moz-border-radius: 23px;
    border-radius: 23px;
    opacity: 0.5;
    filter: alpha(opacity=50);
}
.carousel-control.right {
    left: auto;
    right: 15px;
}
.carousel-control:hover {
    color: #ffffff;
    text-decoration: none;
    opacity: 0.9;
    filter: alpha(opacity=90);
}
.carousel-caption {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 10px 15px 5px;
    background: #333333;
    background: rgba(0, 0, 0, 0.75);
}
.carousel-caption h4,
.carousel-caption p {
    color: #ffffff;
}
.hero-unit {
    padding: 60px;
    margin-bottom: 30px;
    background-color: #eeeeee;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
}
.hero-unit h1 {
    margin-bottom: 0;
    font-size: 60px;
    line-height: 1;
    color: inherit;
    letter-spacing: -1px;
}
.hero-unit p {
    font-size: 18px;
    font-weight: 200;
    line-height: 27px;
    color: inherit;
}
.pull-right {
    float: right;
}
.pull-left {
    float: left;
}
.hide {
    display: none;
}
.show {
    display: block;
}
.invisible {
    visibility: hidden;
}
.utopia-widget-icon {
    display: inline-block;
    float: left;
    height: 18px;
    margin: 10px 0 0 15px;
    width: 18px;
}

/* Header css start */
.header-top {
    background-color: #feffff;
    background: -webkit-gradient(linear, left top, left bottom, from(#feffff), to(#f9f9f9));
    background: -webkit-linear-gradient(top, #feffff, #f9f9f9);
    background: -moz-linear-gradient(top, #feffff, #f9f9f9);
    background: -ms-linear-gradient(top, #feffff, #f9f9f9);
    background: -o-linear-gradient(top, #feffff, #f9f9f9);
    filter: progid:dximagetransform.microsoft.gradient(startColorstr='#feffff', endColorstr='#f9f9f9', GradientType=0);
    /* IE6-9 */

    border: 1px solid #DDDDDD;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    margin: 10px 0 10px 0;
    min-height: 60px;
    position: relative;
}
.header-wrapper {
    padding: 5px 10px;
}
.header-wrapper .utopia-logo {
    font-size: 40px;
}
.header-right {
    position: absolute;
    top: 0;
    right: 0;
}
.header-divider {
    border-left: 1px solid #fff;
    border-right: 1px solid #ddd;
    height: 60px;
    padding: 20px 10px;
    position: relative;
    float: left;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
}
.header-right .header-divider:first-child {
    border-left: none;
}
.header-right .user-panel {
    border-right: none;
}
.header-right .search-box {
    display: inline-block;
}
.search-box form {
    display: none;
}
.header-right .search-box input {
    border: 1px solid #eee;
    -webkit-box-shadow: 0 1px 0 #fcfcfc;
    -moz-box-shadow: 0 1px 0 #fcfcfc;
    box-shadow: 0 1px 0 #fcfcfc;
    margin-top: -10px;
    padding: 10px;
    width: 120px;
}
.notification-wrapper {
    display: inline-block;
    min-height: 50px;
    position: relative;
}
.notification-wrapper .notification-counter {
    padding: 5px 8px;
    color: #000;
    background: #d0d0d0;
    font-weight: bold;
    border: 1px solid rgba(255, 255, 255, 0);
    outline: none;
}
.notification-wrapper .notification-active {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background: #fff;
    border-top: 1px solid #eee;
    border-right: 1px solid #eee;
    border-left: 1px solid #eee;
    border-bottom: none;
    z-index: 20;
    position: relative;
    color: #000;
}
#triangle-down {
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 10px solid #d0d0d0;
    position: relative;
    right: -7px;
    top: 4px;
}
.notification-wrapper .notification-box {
    display: none;
    background: none repeat scroll 0 0 #FFFFFF;
    border: 1px solid #EEEEEE;
    border-radius: 2px 0 2px 2px;
    padding: 10px;
    position: absolute;
    right: 0;
    top: 21px;
    width: 350px;
    z-index: 10;
}

@media all and (max-width: 450px){
    .notification-wrapper .notification-box{
        width: 200px;
    }
}

.message-list,
.user-dropbox ul {
    font-size: 11px;
    line-height: 16px;
    list-style: outside;
    margin-left: 5px;
}
.message-list li {
    border-top: 1px solid #ddd;
    background-position: 8px 6px;
    line-height: 20px;
}
.message-list li,
.user-dropbox li {
    background-repeat: no-repeat;
    display: block;
    background-color: #fff;
}
.message-list li.message {
    background-image: url("../img/icons/mail.png");
}
.message-list li.user,
.user-dropbox li.user {
    background-image: url("../img/icons/administrator.png");
}
.message-list li.call {
    background-image: url("../img/icons/chat-exclamation.png");
}
.message-list li.calendar {
    background-image: url("../img/icons/calendar.png");
}
.message-list li.settings,
.user-dropbox li.settings {
    background-image: url("../img/icons/security.png");
}
.user-dropbox li.dark-theme{
    background-image: url("../img/icons/sun.png");
}
.user-dropbox li.white-theme{
    background-image: url("../img/icons/brightness.png");
}
.user-dropbox li.wooden-theme{
    background-image: url("../../img/icons/gear.png");
}
.user-dropbox li.logout {
    background-image: url('../img/icons/login.png');
}
.message-list li.follow {
    background-image: url("../img/icons/eye.png");
}
.message-list li.new {
    background-color: #f7f7f7;
}
.message-list li .msg {
    background: #fff;
    margin-left: 35px;
    padding: 5px;
}
.message-list li.new .msg {
    background: #fbfbfb;
}
.message-list li a.subject {
    color: #333333;
    display: block;
    font-weight: bold;
    margin: 2px 0;
}
.message-list li a.subject:hover {
    color: #666666;
    text-decoration: none;
}
.message-more a {
    background: #e1e1e1;
    color: #333333;
    display: block;
    font-size: 12px;
    font-weight: bold;
    margin-top: 5px;
    padding: 5px 0;
    text-align: center;
}
.message-more a:hover {
    background: #dcdcdc;
    color: #111111;
    text-decoration: none;
}
.user-panel {
    position: relative;
}
.user-info {
    background-image: url('../img/icons/directional_down.png');
    background-repeat: no-repeat;
    background-position: 123px 10px;
    border: 1px solid #eee;
    width: 120px;
}
.user-info,
.user-active {
    background-color: #fff;
    padding: 10px 20px 10px 10px;
    margin-top: -10px;
    position: relative;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    z-index: 20;
}
.user-active {
    background-image: url('../img/icons/directional_up.png');
    background-repeat: no-repeat;
    background-position: 123px 10px;
    border-bottom: none;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    height: 35px;
}
.user-info a {
    padding: 0 5px;
    outline: none;
}
.user-dropbox {
    background: #fff;
    border: 1px solid #eee;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    position: absolute;
    right: 10px;
    top: 65px;
    width: 200px;
    z-index: 10;
    display: none;
}
.user-dropbox ul {
    margin: 10px 5px;
    list-style: outside none;
}
.user-dropbox li {
    background-position: 15px 5px;
    font-size: 13px;
    line-height: 30px;
    padding-left: 15px;
}
.user-dropbox li:hover {
    background-color: #f7f7f7;
}
.user-dropbox li a {
    margin-left: 30px;
}

@media all and (max-width: 450px){
    .user-info a{
        display: none;
    }

    .user-info{
        width: 8px;
    }

    .search-panel{
        display: none;
    }
}

.breadcrumb {
    background-color: #f5f5f5;
    background: -webkit-gradient(linear, left top, left bottom, from(#f5f5f5), to(#ffffff));
    background: -webkit-linear-gradient(top, #f5f5f5, #ffffff);
    background: -moz-linear-gradient(top, #f5f5f5, #ffffff);
    background: -ms-linear-gradient(top, #f5f5f5, #ffffff);
    background: -o-linear-gradient(top, #f5f5f5, #ffffff);
    filter: progid:dximagetransform.microsoft.gradient(startColorstr='#feffff', endColorstr='#f9f9f9', GradientType=0);
    /* IE6-9 */

    box-shadow: none;
}
/* Header page css end */


/* Side bar css start */

.sidebar .sidebar-toggle {
    margin-bottom: 0;
}

.leftmenu ul {
    margin: 0;
    position: relative;
}
.leftmenu ul li {
    display: block;
    width: 100%;
}
.leftmenu ul li a {
    background: url(../img/sprites.png) no-repeat scroll 8px center;
    border-color: -moz-use-text-color #dddddd #dddddd;
    border: 1px solid #DDDDDD;
    border-width: 0 1px 1px;
    color: #666666;
    display: block;
    padding-left: 35px;
    background-color: #FCFCFC;
}
.leftmenu ul li a:hover {
    background-color: #EEEEEE !important;
    color: #333333;
    text-decoration: none;
}
.leftmenu ul li:first-child a {
    border-top: 1px solid #DDDDDD;
}
.leftmenu ul li a span {
    background: none repeat scroll 0 0 #FFFFFF;
    display: block;
    padding: 8px 10px;
}
.leftmenu ul li a:hover span {
    background: #F7F7F7;
}
.leftmenu ul li.current a {
    background-color: #EEEEEE !important;
    color: #000;
}
.leftmenu ul li.current a span {
    background: #f7f7f7;
}
.leftmenu ul li a.menudrop:hover span {
    background: url("../images/menudroparrow.png") no-repeat scroll right 0 #f7f7f7;
}
.leftmenu ul li.current a.menudrop:hover span {
    background: url("../images/menudroparrow.png") no-repeat scroll right -31px #333333;
}
.leftmenu ul li a.active {
    background-color: #EEEEEE;
}
.leftmenu ul li a.active span {
    background: url("../images/menudroparrow.png") no-repeat scroll right 0 #f7f7f7;
    border-left: 1px solid #DDDDDD;
}
.leftmenu ul li.current a.active {
    background-color: #222222 !important;
}
.leftmenu ul li.current a.active span {
    background: url("../images/menudroparrow.png") no-repeat scroll right -31px #333333;
    border-left: 1px solid #444444;
}
.leftmenu ul li a em {
    font-style: normal;
}
.leftmenu ul li .dropdown {
    display: none;
    margin: -43px 0 10px -33px;
    float: left;
    left: 210px;
    list-style: none;
    position: absolute;
    width: 100%;
    z-index: 9999;
    background: #fff;
}
.leftmenu ul li .dropdown li:hover {
    background: #eee;
}

.lefticon > .leftmenu{
    overflow: visible;
}

.lefticon ul li .sub-menu {
    display: none;
    margin: -47px 0 0px 0px;
    list-style: none;
    position: absolute;
    width: 100%;
    left: 38px;
    z-index: 9999;
}
.lefticon ul li .sub-menu li {
    width: 250px;
    position: relative;
    float: left;
    display: block;
}

.lefticon .leftmenu ul li a {
    height: 45px;
    width: 2px;
}
.lefticon .leftmenu ul li a span {
    display: none;
}
.leftmenu ul li a.dashboard {
    background-position: -154px -438px;
}
.leftmenu ul li a.widgets {
    background-position: -190px -438px;
}
.leftmenu ul li a.tables {
    background-position: -82px -474px;
}
.leftmenu ul li a.elements {
    background-position: -10px -438px;
}
.leftmenu ul li a.charts {
    background-position: -119px -474px;
}
.leftmenu ul li a.media {
    background-position: -47px -403px;
}
.leftmenu ul li a.form {
    background-position: -154px -258px;
}
.leftmenu ul li a.editor {
    background-position: -154px -258px;
}
.leftmenu ul li a.grid {
    background-position: -190px -438px;
}
.leftmenu ul li a.calendar {
    background-position: -82px -150px;
}
.leftmenu ul li a.buttons {
    background-position: -47px -438px;
}
.leftmenu ul li a.chat {
    background-position: -119px -293px;
}
.leftmenu ul li a.contacts {
    background-position: -82px -227px;
}
.leftmenu ul li a.users {
    background-position: -82px -191px;
}
.leftmenu ul li a.error {
    background-position: -119px -114px;
}
.leftmenu ul li a.simple{
    background-position: -9px -475px;
}
.leftmenu ul li a.wizard-form {
    background-position: -46px -113px;
}
.leftmenu ul li a.gallery {
    background-position: -82px -438px;
}
.leftmenu ul li a.fluidgallery {
    background-position: -46px -402px;
}
.leftmenu ul li a.slidergallery {
    background-position: -9px -402px;
}
.leftmenu ul li a.videogallery {
    background-position: -118px -438px;
}
.leftmenu ul li a.list {
    background-position: -45px -474px;
}
.leftmenu ul li a.barcode {
    background: url(../img/icons/barcode.png) no-repeat scroll center;
    background-position: 9px 11px;
    background-color: #FCFCFC;
}
.leftmenu ul li a.icons {
    background-position: -9px -114px;
    background-color: #FCFCFC;
}

/* Sidebar css end */

/* Barcode css start */

.utopia-barcode {
    margin-top: 40px;
    margin-bottom: 40px;
    margin-left: auto;
    margin-right: auto;
    height: 100px;
}
.utopia-qrcode {
    margin-top: 40px;
    margin-bottom: 40px;
    margin-left: auto;
    margin-right: auto;
    height: 100px;
    margin-left: 180px;
}

/* Barcode css end */

/* Calendar css start */

.grey_grad {
    background: #eeeeee;
    /* Old browsers */

    background: -moz-linear-gradient(top, #eeeeee 0%, #b7b7b7 100%);
    /* FF3.6+ */

    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #eeeeee), color-stop(100%, #b7b7b7));
    /* Chrome,Safari4+ */

    background: -webkit-linear-gradient(top, #eeeeee 0%, #b7b7b7 100%);
    /* Chrome10+,Safari5.1+ */

    background: -o-linear-gradient(top, #eeeeee 0%, #b7b7b7 100%);
    /* Opera 11.10+ */

    background: -ms-linear-gradient(top, #eeeeee 0%, #b7b7b7 100%);
    /* IE10+ */

    background: linear-gradient(top, #eeeeee 0%, #b7b7b7 100%);
    /* W3C */

    filter: progid:dximagetransform.microsoft.gradient(startColorstr='#eeeeee', endColorstr='#b7b7b7', GradientType=0);
    /* IE6-9 */

}
.utopia-calendar-highlight {
    -moz-border-radius: 15px;
    border: 1px solid #EEE;
    height: 120px;
    /*width:450px;*/

    margin: 0px;
    overflow: hidden;
    background-color: #FFF;
}
.utopia-calendar-highlight .utopia-calendar-highlight-date {
    float: left;
    font-size: 40px;
    color: #db4a37;
    margin-top: 47px;
    margin-left: 30px;
}
.utopia-calendar-highlight .utopia-calendar-highlight-day {
    float: left;
    margin-left: 5%;
    padding-left: 5%;
    margin-top: 25px;
    border-left: 1px dashed #CCC;
}
.utopia-calendar-highlight .utopia-calendar-highlight-day h1 {
    font-size: 20px;
    text-transform: capitalize;
    letter-spacing: 0.35em;
    color: #222;
}
.utopia-calendar-highlight .utopia-calendar-highlight-day h2 {
    font-size: 15px;
    letter-spacing: 0.2em;
    color: #666;
}
.utopia-calendar-highlight .utopia-calendar-highlight-icon {
    float: right;
    width: 120px;
    background-color: #CCC;
    height: 120px;
    margin: 0px;
    -moz-border-radius-topright: 10px;
    -moz-border-radius-bottomright: 10px;
}
.utopia-calendar-highlight .utopia-calendar-highlight-icon img {
    width: 64px;
    margin: 25px;
    margin-left: 30px;
}
ul.utopia-calendar-tasks {
    margin: 0px;
    margin-top: 20px;
    /*width:450px;*/

    list-style: none;
    list-style-image: none;
    padding: 0px;
    border: 1px solid #DDD;
    border-radius: 10px;
    overflow: hidden;
}
ul.utopia-calendar-tasks li {
    padding-left: 10px;
    border-bottom: 1px solid #DDD;
}
ul.utopia-calendar-tasks li div.utopia-calendar-tasks-title {
    padding-top: 10px;
    padding-bottom: 10px;
    width: 50%;
    float: left;
}
ul.utopia-calendar-tasks li div.utopia-calendar-tasks-time {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
    text-align: right;
    float: right;
    width: 30%;
    background-color: #EFEFEF;
}
ul.utopia-calendar-tasks li.utopia-calendar-tasks-highlighted {
    background: #eeeeee;
    /* Old browsers */

    background: -moz-linear-gradient(top, #eeeeee 0%, #b7b7b7 100%);
    /* FF3.6+ */

    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #eeeeee), color-stop(100%, #b7b7b7));
    /* Chrome,Safari4+ */

    background: -webkit-linear-gradient(top, #eeeeee 0%, #b7b7b7 100%);
    /* Chrome10+,Safari5.1+ */

    background: -o-linear-gradient(top, #eeeeee 0%, #b7b7b7 100%);
    /* Opera 11.10+ */

    background: -ms-linear-gradient(top, #eeeeee 0%, #b7b7b7 100%);
    /* IE10+ */

    background: linear-gradient(top, #eeeeee 0%, #b7b7b7 100%);
    /* W3C */

    filter: progid:dximagetransform.microsoft.gradient(startColorstr='#eeeeee', endColorstr='#b7b7b7', GradientType=0);
    /* IE6-9 */

    font-weight: bold;
    color: #222;
}
ul.utopia-calendar-tasks li.utopia-calendar-tasks-highlighted div.utopia-calendar-tasks-time {
    background-color: transparent;
}
ul.utopia-calendar-tasks li.utopia-tasks-mark-red {
    list-style: disc;
}
.utopia-widget-content-nopadding .utopia-calendar-highlight {
    margin-top: 10px;
}
.utopia-calendar-day {
    width: 100%;
    margin-top: 10px;
    height: 547px;
}
.fc-header-title h2 {
    font-size: 16px;
    line-height: 28px;
    margin-left: 10%;
}
@media all and (max-width: 400px) {
    .utopia-calendar-highlight-icon {
        display: none;
    }
}
@media all and (max-width: 1000px) {
    .utopia-calendar-highlight-icon {
        display: none;
    }
}
@media all and (min-width: 430px) and (max-width: 768px) {
    .utopia-calendar-highlight-icon {
        display: block;
    }
}

.utopia-calendar-box div{
    /*margin: auto;*/
    text-align: center;
}

.utopia-weather{
    /*background: #6f9dbe;
 background: -webkit-gradient(linear, left top, left bottom, from(#FFFFFF), to(#B5B5B5));
 background: -moz-linear-gradient(top, #FFFFFF, #B5B5B5);*/
    height: 100%;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    min-height: 225px;
}

/* Calendar css end */

/* Chart css start */
.legends {
    background-color: #FFFFFF;
    border: 1px solid #888;
    border-radius: 5px;
    color: #222;
    padding: 10px;
    text-align: center;
    opacity: 0.6;
    cursor: pointer;
}
#tooltip,
#tooltipPie,
.legendBG {
    background-color: #FFFFFF;
    border: 1px solid #888;
    border-radius: 5px;
    color: #222;
    padding: 10px;
    text-align: center;
    opacity: 0.6;
    cursor: pointer;
}
.utopia-chart-legend {
    text-align: center;
    border: 1px solid #ddd;
    height: 150px;
    background-color: #FFF;
    margin-bottom: 20px;
}
.utopia-chart-legend .utopia-chart-sparkline {
    margin-top: 20px;
    height: 48px;
}
.utopia-chart-legend .utopia-chart-icon {
    margin: auto;
    margin-top: 20px;
}
.utopia-chart-legend .utopia-chart-icon img {
    width: 48px;
}
.utopia-chart-legend .utopia-chart-heading {
    font-size: 36px;
    text-align: center;
    margin-top: 15px;
}
.utopia-chart-legend .utopia-chart-desc {
    color: #888;
    font-size: 13px;
    text-align: center;
    margin-top: 15px;
}
.utopia-widget {
    /*overflow: hidden;*/

}
.utopia-chart-footer {
    height: 40px;
    border-top: 1px solid #DDD;
    margin-top: 10px;
    margin-bottom: 0px;
}
.utopia-chart-footer .utopia-chart-footer-element {
    width: 49%;
    border-right: 1px solid #DDD;
    float: left;
    height: 54px;
}
.utopia-chart-footer .no-border-right {
    border-right: none;
}
.utopia-chart-footer .utopia-chart-heading {
    text-align: center;
    margin-top: 5px;
    font-size: 16px;
}
.utopia-chart-footer .utopia-chart-desc {
    text-align: center;
    margin-top: 5px;
}
#chart-gauge1 {
    margin-left: 70px;
}
.utopia-small-gauges {
    min-height: 314px;
}
.utopia-small-gauges canvas {
    margin-top: 40px;
    margin-left: 20px;
    float: left;
}
@media all and (max-width: 1080px) {
    #chart-gauge1 {
        width: 80%;
        margin-left: 10%;
    }
}
@media tablet and (max-width: 1080px) {
    #chart-gauge1-container {
        height: 360px;
    }
    #chart-gauge1 {
        width: 80%;
        margin-left: 10%;
    }
}
/* Chart css end */

/* Conversation page css start */
.utopia-chat {
    overflow: hidden;
    margin-bottom: 20px;
}
.utopia-chat img,
.utopia-chat .rounded {
    float: left;
    margin-right: 10px;
}
.utopia-chat img {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
}
.utopia-chat img.rounded {
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
}
.utopia-chat .text {
    margin-left: 45px;
}
.utopia-chat .text-right {
    margin: 0 45px 0 0;
}
.utopia-chat .status,
.utopia-chat .status-alter {
    height: 25px;
    padding: 10px 0 0 10px;
    border-bottom: 1px solid #e8e8e8;
    -webkit-border-top-right-radius: 2px;
    -webkit-border-bottom-right-radius: 0;
    -webkit-border-bottom-left-radius: 0;
    -webkit-border-top-left-radius: 2px;
    -moz-border-radius-topright: 2px;
    -moz-border-radius-bottomright: 0;
    -moz-border-radius-bottomleft: 0;
    -moz-border-radius-topleft: 2px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 2px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    font-size: 12px;
    border-right: 1px solid #ebebeb;
    border-left: 1px solid #ebebeb;
    border-top: 1px solid #ebebeb;
}
.utopia-chat .status {
    background-color: #f9f9f9;
    background: -webkit-gradient(linear, left top, left bottom, from(#f9f9f9), to(#fef9f9));
    background: -webkit-linear-gradient(top, #f9f9f9, #fef9f9);
    background: -moz-linear-gradient(top, #f9f9f9, #fef9f9);
    background: -ms-linear-gradient(top, #f9f9f9, #fef9f9);
    background: -o-linear-gradient(top, #f9f9f9, #fef9f9);
    filter: progid:dximagetransform.microsoft.gradient(startColorstr='#feffff', endColorstr='#f9f9f9', GradientType=0);
    /* IE6-9 */

}
.utopia-chat .status-alter {
    background-color: #f9f9f9;
    background: -webkit-gradient(linear, left top, left bottom, from(#f9f9f9), to(#ffeeee));
    background: -webkit-linear-gradient(top, #f9f9f9, #ffeeee);
    background: -moz-linear-gradient(top, #f9f9f9, #ffeeee);
    background: -ms-linear-gradient(top, #f9f9f9, #ffeeee);
    background: -o-linear-gradient(top, #f9f9f9, #ffeeee);
    filter: progid:dximagetransform.microsoft.gradient(startColorstr='#feffff', endColorstr='#f9f9f9', GradientType=0);
    /* IE6-9 */

}
.status .date {
    margin-right: 10px;
    float: right;
    font-style: italic;
    text-shadow: 1px 1px 0px rgba(255, 255, 255, 0.92);
}
.utopia-chat .user {
    font-weight: bold;
    text-shadow: 1px 1px 0px rgba(255, 255, 255, 0.92);
    font-size: 14px;
}
.utopia-chat p {
    margin: 0;
    background: #ffffff;
    padding: 2px 10px;
    border-bottom: 1px solid #ebebeb;
    border-left: 1px solid #ebebeb;
    border-right: 1px solid #ebebeb;
}
.utopia-chat p:last-child {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}
.text .msg,
.text .msg-alter {
    border: 1px solid #ebebeb;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    overflow: hidden;
}
.text .date,
.text-right .date {
    display: inline-block;
    font-size: 11px;
    font-style: italic;
    padding: 3px 3px 0;
}
.text .date {
    float: right;
}
.text-right .date {
    float: left;
}
.text .msg {
    background-color: #feffff;
    background: -webkit-gradient(linear, left top, left bottom, from(#feffff), to(#edf7fb));
    background: -webkit-linear-gradient(top, #feffff, #edf7fb);
    background: -moz-linear-gradient(top, #feffff, #edf7fb);
    background: -ms-linear-gradient(top, #feffff, #edf7fb);
    background: -o-linear-gradient(top, #feffff, #edf7fb);
    filter: progid:dximagetransform.microsoft.gradient(startColorstr='#feffff', endColorstr='#f9f9f9', GradientType=0);
    /* IE6-9 */

}
.text .msg-alter {
    background-color: #feffff;
    background: -webkit-gradient(linear, left top, left bottom, from(#feffff), to(#f9f9f9));
    background: -webkit-linear-gradient(top, #feffff, #f9f9f9);
    background: -moz-linear-gradient(top, #feffff, #f9f9f9);
    background: -ms-linear-gradient(top, #feffff, #f9f9f9);
    background: -o-linear-gradient(top, #feffff, #f9f9f9);
    filter: progid:dximagetransform.microsoft.gradient(startColorstr='#feffff', endColorstr='#f9f9f9', GradientType=0);
    /* IE6-9 */

    text-align: right;
}
.utopia-chat .right {
    float: right;
    margin-right: 0;
}
.utopia-chat .triangle,
.utopia-chat .triangle-right {
    width: 9px;
    height: 12px;
}
.utopia-chat .triangle {
    float: left;
    position: relative;
    background-image: url(../img/arrow-left.png);
    position: relative;
    left: 2px;
    top: 5px;
}
.utopia-chat .triangle-right {
    float: right;
    position: relative;
    background-image: url(../img/arrow-right.png);
    position: relative;
    right: 5px;
    top: 5px;
}
.utopia-chat-simple {
    background: #fff;
    padding: 5px 6px 5px 35px;
    position: relative;
    overflow: hidden;
    min-height: 32px;
    border: 1px solid #ebebeb;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    margin-bottom: 20px;
}
.utopia-chat-simple img {
    left: 5px;
    position: absolute;
    top: 5px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
}
.utopia-chat-simple .msg {
    vertical-align: top;
    margin-left: 10px;
}
.utopia-chat-simple .multiline {
    vertical-align: middle;
    margin: 8px 0 0 10px;
}
.utopia-chat-simple .msg,
.utopia-chat-simple .multiline {
    color: #444444;
    display: inline-block;
    overflow: hidden;
    width: 70%;
}
.utopia-chat-simple .date {
    font-size: 11px;
    position: absolute;
    right: 10px;
    top: 12px;
}
.utopia-chat-circle {
    background-color: #feffff;
    background: -webkit-gradient(linear, left top, left bottom, from(#feffff), to(#f9f9f9));
    background: -webkit-linear-gradient(top, #feffff, #f9f9f9);
    background: -moz-linear-gradient(top, #feffff, #f9f9f9);
    background: -ms-linear-gradient(top, #feffff, #f9f9f9);
    background: -o-linear-gradient(top, #feffff, #f9f9f9);
    filter: progid:dximagetransform.microsoft.gradient(startColorstr='#feffff', endColorstr='#f9f9f9', GradientType=0);
    /* IE6-9 */

    padding: 10px;
    -webkit-border-top-right-radius: 0;
    -webkit-border-bottom-right-radius: 5px;
    -webkit-border-bottom-left-radius: 5px;
    -webkit-border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -moz-border-radius-bottomright: 5px;
    -moz-border-radius-bottomleft: 5px;
    -moz-border-radius-topleft: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 0;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #ebebeb;
    margin-bottom: 20px;
    overflow: hidden;
}
.utopia-chat-circle .info span {
    font-style: normal;
    font-size: 12px;
}
.utopia-chat-circle .info,
.utopia-chat-circle .info .date {
    text-shadow: 1px 1px 0px rgba(255, 255, 255, 0.92);
    font-size: 11px;
    font-style: italic;
}
.utopia-chat-circle .info .date {
    text-align: right;
    float: right;
}
.utopia-activity-feeds ul {
    margin: 0;
    position: relative !important;
}
.utopia-activity-feeds ul li {
    list-style: none;
    background-color: #feffff;
    background: -webkit-gradient(linear, left top, left bottom, from(#feffff), to(#f9f9f9));
    background: -webkit-linear-gradient(top, #feffff, #f9f9f9);
    background: -moz-linear-gradient(top, #feffff, #f9f9f9);
    background: -ms-linear-gradient(top, #feffff, #f9f9f9);
    background: -o-linear-gradient(top, #feffff, #f9f9f9);
    filter: progid:dximagetransform.microsoft.gradient(startColorstr='#feffff', endColorstr='#f9f9f9', GradientType=0);
    /* IE6-9 */

    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    padding: 5px !important;
    margin-bottom: 20px !important;
    border: 1px solid #ebebeb;
}
.utopia-mailbox {
    width: 100%;
}
.utopia-mailbox thead tr:first-child {
    height: 50px;
    background-color: #feffff;
    background: -webkit-gradient(linear, left top, left bottom, from(#feffff), to(#f9f9f9));
    background: -webkit-linear-gradient(top, #feffff, #f9f9f9);
    background: -moz-linear-gradient(top, #feffff, #f9f9f9);
    background: -ms-linear-gradient(top, #feffff, #f9f9f9);
    background: -o-linear-gradient(top, #feffff, #f9f9f9);
    filter: progid:dximagetransform.microsoft.gradient(startColorstr='#feffff', endColorstr='#f9f9f9', GradientType=0);
    /* IE6-9 */

    border: 1px solid #ddd;
}
.utopia-mailbox tbody {
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd;
    background: #fff;
}
.utopia-mailbox thead th {
    padding-left: 15px;
    text-align: left;
}
.utopia-mailbox a {
    color: #303030;
    font-size: 13px;
}
.utopia-mailbox tbody .utopia-mailbox-new a {
    font-weight: bold;
}
.utopia-mailbox tbody tr td {
    padding: 10px 5px 10px 15px;
    border-bottom: 1px solid #ddd;
}
.utopia-mailbox thead th:last-child,
.utopia-mailbox tbody td:last-child {
    width: 100px;
}
.utopia-mailbox thead th:first-child,
.utopia-mailbox tbody td:first-child {
    width: 20px;
}
.utopia-mailbox tbody tr {
    margin: 10px 0;
}
.utopia-mail-action {
    margin: 18px 0;
}
/* Conversation page css end */

/* Dashboard page css start */
.utopia-widget-content .utopia-form-freeSpace {
    margin-top: 20px;
}
@media all and (max-width: 1164px) {
    .chzn-container {
        width: 85% !important;
    }
    .chzn-search {
        width: 100% !important;
    }
    .chzn-search input {
        width: 80% !important;
    }
    .chzn-drop {
        width: 99% !important;
    }
    .utopia-chosen-label {
        width: 80% !important;
    }
}
@media all and (max-width: 840px) {
    .sample-form-chosen {
        width: 50% !important;
    }
    .chzn-container {
        width: 70% !important;
    }
    .chzn-search {
        width: 100% !important;
    }
    .chzn-search input {
        width: 70% !important;
    }
    .chzn-drop {
        width: 99% !important;
    }
    .utopia-chosen-form .control-group {
        width: 90%;
    }
}
/* Dashboard page css end */

/* Error 404 */
.utopia-error h1 {
    text-align: center;
    margin-top: 40px;
}
.utopia-error h2 {
    font-size: 18px;
    text-align: center;
    margin-bottom: 40px;
}
/* Error 404 End */

/* Forms CSS */
.text-editor{
    overflow: hidden;
}
.utopia-form-button button {
    margin-left: 20px;
    margin-bottom: 10px;
}
.utopia-alert-button {
    margin-right: 15px;
    margin-bottom: 20px;

}
.utopia-alert-button button {
    margin-right: 15px;
}
.utopia-action-button button {
    margin: 5px 10px;
}
.utopia-all-button button {
    margin: 0px 10px 20px 10px;
}
.utopia-extended-elements-firstBox {
    min-height: 290px;
}
.utopia-nobs-box {
    margin: auto;
    /*min-height: 500px;*/

}
.utopia-forms-nob {
    margin: auto;
}
input.nobs {
    box-shadow: 0;
    border-radius: 0px;
    /*height: 50px;*/

    border: none;
    background: transparent;
    outline: none;
    line-height: 5px;
    text-spacing: 1px;
    font-size: 25px;
}
.utopia-form-commonElement-box {
    min-height: 455px;
}
.utopia-form-notification-box {
    min-height: 350px;
}
.utopia-group-button button {
    margin: 0px 0px 10px 10px;
}
.utopia-form-icon-button .btn-group {
    margin: 0px 5px 5px 5px;
}
.utopia-form-icon-button p a {
    margin: 0px 5px 5px 5px;
}
.utopia-form-different-button {
    margin-bottom: 10px;
    margin-left: 10px;
}
.utopia-button-box {
    min-height: 182px;
}
.utopia-form-box {
    margin-top: 50px;
}
.utopia-badgeFrame {
    min-height: 150px;
}
.utopia-badgeFrame .appreciate {
    float: left;
    margin-left: 20px;
    margin-bottom: 20px;
}
.utopia-badgeFrame .starbar {
    margin-top: 10px;
    margin-left: 20px;
}
.utopia-form-freeSpace {
    margin-top: 20px;
}
.utopia-form-extend {
    margin-top: 20px;
    margin-bottom: 20px;
}
.utopia-form-notification .alert {
    margin-bottom: 20px;
}
.utopia-form-notification .alert .close {
    margin-left: 20px;
    position: relative;
    right: -250px;
    top: 8px;
}
.well {
    background-color: #F5F5F5;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 4px 4px 4px 4px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05) inset;
    margin-bottom: 20px;
    min-height: 20px;
    padding: 10px;
}
.utopia-form-tooltip p a {
    color: #0000ff;
}
.utopia-form-freeSpace-cdl {
    margin-bottom: 28px;
}
.wized-prev {
    float: left;
    cursor: pointer;
    display: inline-block;
    *display: inline;
    /* IE7 inline-block hack */

    *zoom: 1;
    padding: 4px 10px 4px;
    margin-bottom: 0;
    font-size: 13px;
    line-height: 18px;
    *line-height: 20px;
    color: #333333;
    text-align: center;
    text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
    vertical-align: middle;
    cursor: pointer;
    background-color: #f5f5f5;
    background-image: -moz-linear-gradient(top, #ffffff, #e6e6e6);
    background-image: -ms-linear-gradient(top, #ffffff, #e6e6e6);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffffff), to(#e6e6e6));
    background-image: -webkit-linear-gradient(top, #ffffff, #e6e6e6);
    background-image: -o-linear-gradient(top, #ffffff, #e6e6e6);
    background-image: linear-gradient(top, #ffffff, #e6e6e6);
    background-repeat: repeat-x;
    filter: progid:dximagetransform.microsoft.gradient(startColorstr='#ffffff', endColorstr='#e6e6e6', GradientType=0);
    border-color: #e6e6e6 #e6e6e6 #bfbfbf;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    *background-color: #e6e6e6;
    /* Darken IE7 buttons by default so they stand out more given they won't have borders */

    filter: progid:dximagetransform.microsoft.gradient(enabled=false);
    border: 1px solid #cccccc;
    *border: 0;
    border-bottom-color: #b3b3b3;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    *margin-left: .3em;
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
}
.prev:hover {
    color: #a52a2a;
}
.wized-next:hover {
    color: #a52a2a;
}
.wized-next {
    float: right;
    cursor: pointer;
    display: inline-block;
    *display: inline;
    /* IE7 inline-block hack */

    *zoom: 1;
    padding: 4px 10px 4px;
    margin-bottom: 0;
    font-size: 13px;
    line-height: 18px;
    *line-height: 20px;
    color: #333333;
    text-align: center;
    text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
    vertical-align: middle;
    cursor: pointer;
    background-color: #f5f5f5;
    background-image: -moz-linear-gradient(top, #ffffff, #e6e6e6);
    background-image: -ms-linear-gradient(top, #ffffff, #e6e6e6);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffffff), to(#e6e6e6));
    background-image: -webkit-linear-gradient(top, #ffffff, #e6e6e6);
    background-image: -o-linear-gradient(top, #ffffff, #e6e6e6);
    background-image: linear-gradient(top, #ffffff, #e6e6e6);
    background-repeat: repeat-x;
    filter: progid:dximagetransform.microsoft.gradient(startColorstr='#ffffff', endColorstr='#e6e6e6', GradientType=0);
    border-color: #e6e6e6 #e6e6e6 #bfbfbf;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    *background-color: #e6e6e6;
    /* Darken IE7 buttons by default so they stand out more given they won't have borders */

    filter: progid:dximagetransform.microsoft.gradient(enabled=false);
    border: 1px solid #cccccc;
    *border: 0;
    border-bottom-color: #b3b3b3;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    *margin-left: .3em;
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
}
#steps li {
    float: left;
    padding: 2px 5px;
    margin: 0px 10px 20px 0px;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
}
#steps li.current {
    color: #498FB0;
    font-size: 15px;
    border: 1px solid #FBEEEE;
}
.clearBoth {
    clear: both;
}
#steps li span {
    font-size: 11px;
    display: block;
}
.utopia-radio-1 {
    float: left;
}
.utopia-feature {
    margin-left: 40px;
    margin-bottom: 20px;
}
.utopia-option-1 {
    float: left;
}
.utopia-white {
    margin-top: 20px;
}
.growl-button {
    min-height: 85px;
}
.growl-button .utopia-alert-button button {
    margin-left: 10px;
    margin-bottom: 10px;
}
.utopia-date-box {
    min-height: 145px;
}
.success-input {
    border-color: #468847;
    color: #468847;
}
.success-input:focus {
    border-color: #356635;
    -webkit-box-shadow: 0 0 6px #7aba7b;
    -moz-box-shadow: 0 0 6px #7aba7b;
    box-shadow: 0 0 6px #7aba7b;
}
.error-input {
    color: #b94a48;
    border-color: #b94a48;
}
.error-input:focus {
    border-color: #953b39;
    -webkit-box-shadow: 0 0 6px #d59392;
    -moz-box-shadow: 0 0 6px #d59392;
    box-shadow: 0 0 6px #d59392;
}
.wizard-form-chosen {
    width: 80%;
}
.iagree {
    margin-left: 10px;
}
#dialog_link span.ui-icon,
#modal_link span.ui-icon {
    margin: 0 5px 0 0;
    position: absolute;
    left: .2em;
    top: 50%;
    margin-top: -8px;
}
ul#icons {
    margin: 0 0 20px 0;
    padding: 0;
}
ul#icons li {
    margin: 4px;
    padding: 5px 0;
    position: relative;
    cursor: pointer;
    float: left;
    list-style: none;
}
ul#icons span.ui-icon {
    float: left;
    margin: 0 4px;
}
ul.roundButton {
    margin: 0 0 20px 0;
    padding: 0;
}
ul.roundButton li {
    margin: 4px;
    padding: 5px 0;
    position: relative;
    cursor: pointer;
    float: left;
    list-style: none;
}
a.utopia-round-button {
    height: 25px;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
}
a.utopia-round-button i {
    margin-top: 5px;
}
@media all and (max-width: 1164px) {
    .chzn-container {
        width: 100% !important;
    }
    .chzn-search {
        width: 80% !important;
    }
    .chzn-search input {
        width: 80% !important;
    }
    .chzn-drop {
        width: 99% !important;
    }
    .utopia-chosen-label {
        width: 80% !important;
    }
}
/*@media all and (max-width: 1164px) {
.sample-form-chosen.chzn-container{
        width:60% !important;
        background-color: blue;
    }
}*/
@media all and (max-width: 1164px) {
    .sample-form-chosen {
        width: 60%;
    }
}
@media all and (max-width: 750px) {
    .sample-form-chosen {
        width: 30%;
    }
}
@media all and (max-width: 1164px) {
    .wizard-form-chosen {
        width: 40%;
    }
}
@media all and (max-width: 1164px) {
    .wizard-form-chosen {
        width: 40%;
    }
}
@media all and (max-width: 750px) {
    .wizard-form-chosen {
        width: 20%;
    }
}
@media all and (max-width: 750px) {
    .input-fluid {
        width: 40% !important;
        /*background-color: blue;*/

    }
}
@media all and (max-width: 1080px) {
    iframe {
        width: 70%;
    }
}
@media all and (max-width: 1080px) {
    .utopia-extended-elements-firstBox {
        min-height: 430px !important;
    }
}
@media all and (max-width: 767px) {
    .utopia-extended-elements-firstBox {
        min-height: 185px !important;
    }
}
@media all and (max-width: 1080px) {
    .utopia-button-box {
        min-height: 320px !important;
    }
}
@media all and (max-width: 767px) {
    .utopia-button-box {
        min-height: 170px !important;
    }
}
@media all and (max-width: 1180px) {
    .tagedit-list {
        width: 98% !important;
    }
}
.utopia-date-box {
    margin: auto;
}
.utopia-date {
    height: 250px;
}
input.nobs {
    height: 20px\9 !important;
    padding-bottom: 5px\9 !important;
    padding-top: 5px\9 !important;
    font-size: 15px\9 !important;
}
.utopia-calendar-box div {
    /*margin: auto;*/

    text-align: center;
}
.utopia-short-button {
    margin-top: 10px;
    margin-bottom: 20px;
}
.commutator {
    margin-left: 20px;
}
.utopia-long-button {
    margin-top: 10px;
    margin-bottom: 20px;
}
.commutator {
    margin-left: 20px;
}
/* Forms Css End */

/* Gallery pages Title and Content css properties starts */

#utopia-tiles li {
    width: 150px
}

#utopia-tiles li p {
    font-size: 12px
}

@media all and (min-width:200px) {
    #utopia-woomarks #utopia-tiles li {
        width: 150px
    }

    #utopia-woomarks #utopia-tiles li .widget .utopia-icon-views {
        font-size: 12px;
        float: right;
        height: 20px;
        padding-left: 25px;
        padding-top: 2px;
        background: url(../img/icons/eye.png) no-repeat;
        background-position: 8% 80%;
        background-size: 14px 14px;
        padding-right: 5px
    }

    #utopia-woomarks #utopia-tiles li .widget .utopia-icon-comments {
        font-size: 12px;
        float: right;
        height: 20px;
        padding-left: 23px;
        padding-top: 2px;
        background: url(../img/icons/messenger.png) no-repeat;
        background-position: 8% 80%;
        background-size: 14px 14px;
        padding-right: 5px
    }

    #utopia-woomarks #utopia-tiles li .widget .upotia-icon-fans {
        font-size: 12px;
        float: right;
        height: 20px;
        padding-left: 23px;
        padding-top: 2px;
        background: url(../img/icons/hand_thumbsup.png) no-repeat;
        background-position: 8% 80%;
        background-size: 14px 14px;
        padding-right: 5px
    }

    #utopia-woomarks #utopia-tiles li .widget span a {
        color: #000
    }
}

.carousel {
    position: relative;
    margin-top: 4px;
    margin-bottom: 4px
}

.carousel .item img {
    max-width: 100%
}

@media all and (min-width:80px) {
    #gallery img {
        padding: 5px 5px 5px 0;
    }

    #galleries img {
        margin: 2px 2px 5px 3px
    }
}

#utopia-wrap {
    width: 100%;
    height: 500px;
    position: relative;
    z-index: 0
}

#utopia-camera-wrap {
    width: 100%;
    height: 100%;
    position: relative;
    top: 0;
    left: 0
}


#utopia-pagination {
    margin-right: 10px
}

#utopia-image-upload {
    width: 100%;
    position: relative
}

#utopia-image-upload #utopia-upload-preview {
    text-align: left
}

#utopia-image-upload #utopia-upload-name {
    text-align: right
}

#utopia-image-upload #utopia-upload-size {
    text-align: right
}

#utopia-image-upload #utopia-upload-delete {
    text-align: right
}

#utopia-image-upload #utopia-upload-start {
    text-align: right
}

#utopia-image-upload #utopia-upload-cancel {
    text-align: right
}

.utopia-video-gallery, #video-gallery {
    width: 100% !important;
    height: 200px !important
}

#utopia-video-titles {
    background: #eee;
    text-align: center;
    height: 30px;
    padding-top: 10px;
    font-size: 13px
}

@media all and (min-width:400px) {
    .utopia-video-galleries ul {
        position: relative
    }

    .utopia-video-galleries ul li {
        float: left;
        position: relative;
        display: inline-block;
        margin: 7px;
        width: 31%;
        height: auto;
        background: #eee;
        list-style: none
    }

    .utopia-video-galleries ul li .utopia-gallery-video-title {
        position: absolute;
        background-color: #808080;
        top: 150px;
        left: 0;
        height: 40px;
        width: 100%;
        text-align: center;
        font-weight: 700;
        font-size: 16px;
        color: #f8f8ff;
        padding-top: 10px;
        z-index: 1;
        -ms-filter: "alpha(Opacity=50)";
        filter: alpha(opacity = 30);
        opacity: .3
    }

    .utopia-video-galleries ul li img{
        max-width: 100%;
        height: 200px;
        width: 100%;
    }

    .utopia-video-galleries ul li .utopia-video-info {
        padding: 10px
    }
}

.utopia-embed-video iframe {
    width: 100%
}

#embedform {
    width: 600px;
    margin: 20px auto
}

#embedform .url {
    border: 1px solid #AAA;
    font-size: 1.5em;
    margin: 10px 0;
    padding: 5px;
    width: 75%
}

#embedform .submit {
    float: right;
    font-size: 1.7em;
    margin-top: 10px
}

#embed {
    margin: auto;
    text-align: center;
    width: 600px
}

@media all and (min-width:80px) {
    #utopia-player {
        width: 98%;


        #utopia-player ol {
            width: 92%;
            padding-left: 35px;
        }

        #utopia-player ol li {
            margin-right: 15px;
        }

    }

    .audiojs {
        width: 100%;
        margin-bottom: 10px;
    }

    .audiojs .scrubber {
        width: 50%
    }

    .audiojs .time {
        width: 25%;
        text-align: center
    }
}

/*Gallery page css ends */

/* Index page css starts */
.utopia-login-message {
    text-align: center;
    margin: 20px 0 50px 0;
}
.utopia-login-info {
    text-align: center;
}
.utopia-login-info img {
    width: auto;
}

.utopia-login {
    max-width: 350px;
}

.utopia-login form .utopia-fluid-input {
    width: 100%;
}
.utopia-login .utopia-login-action {
    margin: 15px 0;
}
.utopia-login .utopia-login-action li {
    list-style: none;
}
.utopia-login .utopia-login-action li input {
    display: inline;
}
@media (min-width: 320px) and (max-width: 480px) {
    .utopia-login-info {
        display: none;
    }
    .utopia-login {
        margin: 10px auto;
    }
}


@media (min-width: 230px) and (max-width: 768px) {
    .utopia-login-info img{
        width: 350px;
        margin-top: 30px;
    }
}
/* Index page css end */

/* Maps css start */

.utopia-map {
    height: 300px;
    border: 1px solid #DDD;
    --moz-border-radius-topleft: 10px;
    --moz-border-radius-topright: 10px;
    border: none;
}
/* google maps fix with twitter bootstrap interfering*/
img {
    max-width: none;
}
/* google maps fix with twitter bootstrap interfering end*/
.utopia-map-wrapper {
    height: 430px;
    border-radius: 10px;
    background: #222;
    overflow: hidden;
}
.utopia-map-wrapper .utopia-map-details {
    margin: 10px;
    pdding: 10px;
}
.utopia-map-wrapper .utopia-map-details .utopia-map-description {
    border-left: 6px solid #f09b24;
    margin: 20px 0 20px 30px;
    width: 60%;
    float: left;
}
.utopia-map-wrapper .utopia-map-details .utopia-map-description p {
    padding-left: 10px;
    font-size: 12px;
    font-style: italic;
    color: #EEE;
}
.utopia-map-wrapper .utopia-map-details .utopia-map-action {
    width: 27%;
    float: right;
    margin-top: 25px;
    text-align: right;
}
.utopia-map-wrapper .utopia-map-details .utopia-map-action img {
    marging: 10px;
    margin-right: 10px;
    width: 48px;
    opacity: 0.9;
}
/* Maps css end */

/* Pricing tables page css start */
p.pricing:first-child {
    margin-top: 10px;
}
.pricing {
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    margin-top: 40px;
    padding: 10px 10px;
    background: #ffffff;
    /* Old browsers */

    background: -moz-linear-gradient(top, #ffffff 0%, #f5f5f5 100%);
    /* FF3.6+ */

    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ffffff), color-stop(100%, #f5f5f5));
    /* Chrome,Safari4+ */

    background: -webkit-linear-gradient(top, #ffffff 0%, #f5f5f5 100%);
    /* Chrome10+,Safari5.1+ */

    background: -o-linear-gradient(top, #ffffff 0%, #f5f5f5 100%);
    /* Opera 11.10+ */

    background: -ms-linear-gradient(top, #ffffff 0%, #f5f5f5 100%);
    /* IE10+ */

    background: linear-gradient(top, #ffffff 0%, #f5f5f5 100%);
    /* W3C */

    filter: progid:dximagetransform.microsoft.gradient(startColorstr='#ffffff', endColorstr='#f5f5f5', GradientType=0);
    /* IE6-9 */

    -webkit-box-shadow: 1px 2px 1px #dddddd;
    -moz-box-shadow: 1px 2px 1px #dddddd;
    box-shadow: 1px 2px 1px #dddddd;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
}
/******************************* ***********************************

                    Elegent Table starts

******************************* ***********************************/
.utopia-elegent {
    width: 940px;
    margin: 20px auto;
}
.utopia-elegent .utopia-elegent-plan {
    width: 218px;
    float: left;
    margin-left: 20px;
    border: 1px solid #c7c7c7;
    box-shadow: 5px 5px 0 #e5e5e5;
}
.utopia-elegent .utopia-elegent-plan:first-child {
    margin-left: 0;
}
.utopia-elegent .utopia-elegent-plan h3 {
    font-size: 27px;
    text-align: center;
    color: #fff;
    padding: 10px 0 70px;
    font-weight: bold;
}
.utopia-elegent .utopia-elegent-plan h3 span {
    display: block;
    line-height: 20px;
    font-size: 12px;
    text-transform: uppercase;
}
.utopia-elegent .utopia-elegent-highlight h3 .utopia-elegent-packagetype {
    font-size: 27px;
    top: -7px;
}
.utopia-elegent .utopia-elegent-highlight h3 .utopia-elegent-packagefield {
    padding-bottom: 20px;
}
.utopia-elegent .utopia-elegent-select,
.utopia-elegent-plan li {
    text-align: center;
    line-height: 3.8em;
    list-style: none;
}
.utopia-elegent .utopia-elegent-plan li {
    border-bottom: 1px dotted #bfbfbf;
    width: 80%;
    margin: 0 auto;
}
.utopia-elegent .utopia-elegent-plan li:first-child {
    border-top: 1px dotted #bfbfbf;
}
.utopia-elegent .utopia-elegent-select {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAIAAAAmkwkpAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA1xpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDM0MiwgMjAxMC8wMS8xMC0xODowNjo0MyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDpGOTdGMTE3NDA3MjA2ODExODA4M0VCODNDNjJCRDdDMSIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDpGMkIwMzI3Q0ZGQUExMUUwOTRENkY4Q0UwMjZBRjBDOSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDpGMkIwMzI3QkZGQUExMUUwOTRENkY4Q0UwMjZBRjBDOSIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ1M1Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MTdGQjRCODkyMkZFRTAxMUIzNzhDOTA2MzRERERBQTAiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6Rjk3RjExNzQwNzIwNjgxMTgwODNFQjgzQzYyQkQ3QzEiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz6wORDBAAAAIElEQVR42mL89u0bAwMDhGRE4bx58wZIcXFxoXMAAgwAN1YRUSEVmcAAAAAASUVORK5CYII=);
    height: 78px;
    border: 1px solid #c8c8c8;
    border-top: 0;
}
.utopia-elegent .utopia-elegent-select div {
    padding-top: 18px;
    text-shadow: 0 1px 0px #fff;
}
.utopia-elegent .utopia-elegent-features ul {
    border-left: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
    margin: 0;
}
/* specialized
-------------------------------------------------------------------*/
h4 {
    color: #fff;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
}
.utopia-elegent-interval {
    font-size: 11px;
}
.utopia-elegent-amount,
.utopia-elegent-interval {
    display: block;
    text-align: center;
}
.utopia-elegent-amount {
    font-size: 40px;
    position: relative;
    top: 23px;
}
.utopia-elegent-amount span {
    vertical-align: super;
    font-size: 20px;
}
/* Top
-------------------------------------------------------------------*/
.utopia-elegent .p1 h3 {
    background: #99A5C8;
    text-shadow: 1px 1px 0 #6b9d5c;
    border-top: 5px solid #395b7b;
}
.utopia-elegent .p2 h3 {
    background: #BC99C8;
    text-shadow: 1px 1px 0 #5c829d;
    border-top: 5px solid #7b396e;
}
.utopia-elegent .p3 h3 {
    background: #C899A5;
    text-shadow: 1px 1px 0 #2a679b;
    border-top: 5px solid #5e405a;
}
.utopia-elegent .p4 h3 {
    background: #C8B099;
    text-shadow: 1px 1px 0 #9d8b5c;
    border-top: 5px solid #7b6339;
}
.utopia-elegent .p5 h3 {
    background: #C4C899;
    text-shadow: 1px 1px 0 #9d8b5c;
    border-top: 5px solid #7b6339;
}
.utopia-elegent .p6 h3 {
    background: #B1C899;
    text-shadow: 1px 1px 0 #9d8b5c;
    border-top: 5px solid #397b43;
}
.utopia-elegent .p7 h3 {
    background: #99C8C2;
    text-shadow: 1px 1px 0 #9d8b5c;
    border-top: 5px solid #39767b;
}
.utopia-elegent .p8 h3 {
    background: #D0C291;
    text-shadow: 1px 1px 0 #9d8b5c;
    border-top: 5px solid #7b6339;
}
.utopia-elegent .p9 h3 {
    background: #D28F90;
    text-shadow: 1px 1px 0 #9d8b5c;
    border-top: 5px solid #7b3939;
}
.utopia-elegent .p10 h3 {
    background: #96BACB;
    text-shadow: 1px 1px 0 #9d8b5c;
    border-top: 5px solid #39627b;
}
.utopia-elegent .p11 h3 {
    background: #B0B0B0;
    text-shadow: 1px 1px 0 #9d8b5c;
    border-top: 5px solid #6f6f6f;
}
.utopia-elegent h4 {
    height: 120px;
}
.utopia-elegent h4 span.utopia-elegent-amount {
    border-radius: 400px;
    margin: 0 auto;
    padding: 30px 0;
    width: 80px;
    position: relative;
    top: -45px;
}
.utopia-elegent .p1 h4 span.utopia-elegent-amount {
    background: #1f2e5a;
}
.utopia-elegent .p2 h4 span.utopia-elegent-amount {
    background: #4b1f5a;
}
.utopia-elegent .p3 h4 span.utopia-elegent-amount {
    background: #5a1f2e;
}
.utopia-elegent .p4 h4 span.utopia-elegent-amount {
    background: #5a3c1f;
}
.utopia-elegent .p5 h4 span.utopia-elegent-amount {
    background: #555a1f;
}
.utopia-elegent .p6 h4 span.utopia-elegent-amount {
    background: #3d5a1f;
}
.utopia-elegent .p7 h4 span.utopia-elegent-amount {
    background: #1f5a53;
}
.utopia-elegent .p8 h4 span.utopia-elegent-amount {
    background: #645214;
}
.utopia-elegent .p9 h4 span.utopia-elegent-amount {
    background: #671112;
}
.utopia-elegent .p10 h4 span.utopia-elegent-amount {
    background: #1a485e;
}
.utopia-elegent .p11 h4 span.utopia-elegent-amount {
    background: #3c3c3c;
}
.utopia-elegent h3 span {
    font-weight: bold;
    text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.3);
}
.utopia-elegent h4 .tagline {
    text-align: center;
    display: block;
    position: relative;
    top: -22px;
    color: #000;
    font-size: 18px;
    text-transform: uppercase;
    width: 80%;
    margin: 0 auto;
}
.utopia-elegent .utopia-elegent-packagefield,
.utopia-elegent .utopia-elegent-packagetype {
    position: relative;
}
/* Buttons
-------------------------------------------------------------------*/
.utopia-elegent .utopia-elegent-button {
    background: #efc47b;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2VmYzQ3YiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNlNTllNDIiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top, #efc47b 0%, #e59e42 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #efc47b), color-stop(100%, #e59e42));
    background: -webkit-linear-gradient(top, #efc47b 0%, #e59e42 100%);
    background: -o-linear-gradient(top, #efc47b 0%, #e59e42 100%);
    background: -ms-linear-gradient(top, #efc47b 0%, #e59e42 100%);
    background: linear-gradient(top, #efc47b 0%, #e59e42 100%);
    -ms-filter: progid:dximagetransform.microsoft.gradient(startColorstr='#efc47b', endColorstr='#e59e42', GradientType=0);
    border: 1px solid #ba8d15;
    box-shadow: 0 2px 2px #f4d79d inset, 0 -2px 2px #dc9337 inset, 2px 2px 0 #c2c2c2;
    color: #704718;
    display: block;
    font-weight: bold;
    line-height: 35px;
    margin: 10px auto 0;
    width: 122px;
    text-shadow: 1px 1px 0 #f2d093;
    font-size: 14px;
}
.utopia-elegent .utopia-elegent-button:hover,
.utopia-elegent .utopia-elegent-button:hover {
    border: 1px solid #ba8d15;
    background: #ecbf6f;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2VjYmY2ZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNlN2FmNTUiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top, #ecbf6f 0%, #e7af55 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ecbf6f), color-stop(100%, #e7af55));
    background: -webkit-linear-gradient(top, #ecbf6f 0%, #e7af55 100%);
    background: -o-linear-gradient(top, #ecbf6f 0%, #e7af55 100%);
    background: -ms-linear-gradient(top, #ecbf6f 0%, #e7af55 100%);
    background: linear-gradient(top, #ecbf6f 0%, #e7af55 100%);
    -ms-filter: progid:dximagetransform.microsoft.gradient(startColorstr='#ecbf6f', endColorstr='#e7af55', GradientType=0);
}
.utopia-elegent .utopia-elegent-button:hover {
    box-shadow: 0 2px 2px #f4d79d inset, 0 -2px 2px #dc9337 inset, 2px 2px 0 #c2c2c2;
}
.utopia-elegent .utopia-elegent-button:active {
    box-shadow: 0 1px 1px #f4d79d inset, 0 -1px 1px #dc9337 inset, 2px 2px 0 #c2c2c2;
}
/* Highlight
-------------------------------------------------------------------*/
.utopia-elegent .utopia-elegent-highlight h3 {
    padding: 6px 0 50px;
}
.utopia-elegent .featured {
    background: url("/img/pricingtable_images/elegent/bestvalue1.png") no-repeat scroll center top;
    padding: 8px 0 22px;
    color: #FFFFFF;
    display: block;
    font-size: 15px;
    line-height: 1;
    position: relative;
    top: -15px;
    text-shadow: 0 -1px 0 #bb4242;
    font-style: italic;
    text-transform: none !important;
}
.utopia-elegent .utopia-elegent-highlight {
    position: relative;
    margin-top: -25px;
}
.utopia-elegent .featured.f2 {
    background-image: url("/img/pricingtable_images/elegent/bestvalue2.png");
    text-shadow: 0 -1px 0 #42b1bb;
}
.utopia-elegent .featured.f3 {
    background-image: url("/img/pricingtable_images/elegent/bestvalue3.png");
    text-shadow: 0 -1px 0 #9442bb;
}
.utopia-elegent .featured.f4 {
    background-image: url("/img/pricingtable_images/elegent/bestvalue4.png");
    text-shadow: 0 -1px 0 #bb9a42;
}
.utopia-elegent .featured.f5 {
    background-image: url("/img/pricingtable_images/elegent/bestvalue5.png");
    text-shadow: 0 -1px 0 #3fa648;
}
/******************************* ***********************************

                    Glossy Table starts

******************************* ***********************************/
.utopia-glossy {
    width: 888px;
    margin: 50px auto;
}
.utopia-glossy .utopia-glossy-plan {
    width: 205px;
    float: left;
    margin-left: 20px;
    background: #cfcfcf;
    background: -moz-linear-gradient(left, #cfcfcf 0%, #e5e5e5 50%, #cfcfcf 100%);
    background: -webkit-gradient(linear, left top, right top, color-stop(0%, #cfcfcf), color-stop(50%, #e5e5e5), color-stop(100%, #cfcfcf));
    background: -webkit-linear-gradient(left, #cfcfcf 0%, #e5e5e5 50%, #cfcfcf 100%);
    background: -o-linear-gradient(left, #cfcfcf 0%, #e5e5e5 50%, #cfcfcf 100%);
    background: -ms-linear-gradient(left, #cfcfcf 0%, #e5e5e5 50%, #cfcfcf 100%);
    background: linear-gradient(left, #cfcfcf 0%, #e5e5e5 50%, #cfcfcf 100%);
    -ms-filter: progid:dximagetransform.microsoft.gradient(startColorstr='#cfcfcf', endColorstr='#cfcfcf', GradientType=1);
    border: 1px solid #a7a7a7;
    border-radius: 4px;
    box-shadow: 0 0 4px #CDCDCD;
}
.utopia-glossy .utopia-glossy-plan:first-child {
    margin-left: 0;
}
.utopia-glossy .utopia-glossy-plan h3 {
    font-family: "Arial Narrow";
    font-size: 23px;
    text-align: center;
    color: #fff;
    line-height: 45px;
    text-transform: uppercase;
    font-weight: normal;
}
.utopia-glossy .utopia-glossy-plan li {
    background: #ffffff url(/img/pricingtable_images/glossy/borderbottom.png) no-repeat bottom;
    line-height: 3.8em;
    list-style: none;
}
.utopia-glossy-select,
.utopia-glossy-plan li {
    text-align: center;
}
.utopia-glossy .utopia-glossy-plan h3 {
    border-radius: 4px 4px 0 0;
}
.utopia-glossy .utopia-glossy-plan .utopia-glossy-select {
    border-radius: 0 0 4px 4px;
}
.utopia-glossy .utopia-glossy-select div {
    padding-top: 18px;
    text-shadow: 0 1px 0px #fff;
    color: #6d6d6d;
}
.utopia-glossy .utopia-glossy-features ul {
    border-left: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
    margin: 0;
}
/* specialized
-------------------------------------------------------------------*/
h4 {
    color: #fff;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
}
.utopia-glossy-interval {
    font-size: 11px;
    color: #6d6d6d;
}
.utopia-glossy-amount,
.utopia-glossy-interval {
    display: block;
    text-align: center;
}
.utopia-glossy-amount {
    font-size: 40px;
    line-height: 1em;
}
.utopia-glossy-amount span {
    vertical-align: super;
    font-size: 20px;
}
/* Top
-------------------------------------------------------------------*/
.utopia-glossy .utopia-glossy-plan h4 {
    padding: 0 0 10px;
}
.utopia-glossy .utopia-glossy-amount {
    text-shadow: 0 1px 1px #fff;
    font-size: 56px;
    color: #303030;
}
.utopia-glossy h3 {
    margin-top: -1px;
    border-radius: 0 0 3px 3px;
    border-bottom-width: 2px;
}
.utopia-glossy .p1 h3 {
    border: 1px solid #af6610;
    box-shadow: 0 2px 2px #dfb25a inset;
    background: #d69831;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2Q2OTgzMSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNjMjY5MTgiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top, #d69831 0%, #c26918 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #d69831), color-stop(100%, #c26918));
    background: -webkit-linear-gradient(top, #d69831 0%, #c26918 100%);
    background: -o-linear-gradient(top, #d69831 0%, #c26918 100%);
    background: -ms-linear-gradient(top, #d69831 0%, #c26918 100%);
    background: linear-gradient(top, #d69831 0%, #c26918 100%);
    -ms-filter: progid:dximagetransform.microsoft.gradient(startColorstr='#d69831', endColorstr='#c26918', GradientType=0);
}
.utopia-glossy .p2 h3 {
    border: 1px solid #3710af;
    box-shadow: 0 2px 2px #8c5ae2 inset;
    background: #6932db;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzY5MzJkYiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMzNDE3YmMiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top, #6932db 0%, #3417bc 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #6932db), color-stop(100%, #3417bc));
    background: -webkit-linear-gradient(top, #6932db 0%, #3417bc 100%);
    background: -o-linear-gradient(top, #6932db 0%, #3417bc 100%);
    background: -ms-linear-gradient(top, #6932db 0%, #3417bc 100%);
    background: linear-gradient(top, #6932db 0%, #3417bc 100%);
    -ms-filter: progid:dximagetransform.microsoft.gradient(startColorstr='#6932db', endColorstr='#3417bc', GradientType=0);
}
.utopia-glossy .p3 h3 {
    border: 1px solid #7d10af;
    box-shadow: 0 2px 2px #cc5ce6 inset;
    background: #b633dc;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2I2MzNkYyIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiM4MjE4YzMiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top, #b633dc 0%, #8218c3 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #b633dc), color-stop(100%, #8218c3));
    background: -webkit-linear-gradient(top, #b633dc 0%, #8218c3 100%);
    background: -o-linear-gradient(top, #b633dc 0%, #8218c3 100%);
    background: -ms-linear-gradient(top, #b633dc 0%, #8218c3 100%);
    background: linear-gradient(top, #b633dc 0%, #8218c3 100%);
    -ms-filter: progid:dximagetransform.microsoft.gradient(startColorstr='#b633dc', endColorstr='#8218c3', GradientType=0);
}
.utopia-glossy .p4 h3 {
    border: 1px solid #af1011;
    box-shadow: 0 2px 2px #df6759 inset;
    background: #d63d31;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2Q2M2QzMSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNjMjE4MjQiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top, #d63d31 0%, #c21824 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #d63d31), color-stop(100%, #c21824));
    background: -webkit-linear-gradient(top, #d63d31 0%, #c21824 100%);
    background: -o-linear-gradient(top, #d63d31 0%, #c21824 100%);
    background: -ms-linear-gradient(top, #d63d31 0%, #c21824 100%);
    background: linear-gradient(top, #d63d31 0%, #c21824 100%);
    -ms-filter: progid:dximagetransform.microsoft.gradient(startColorstr='#d63d31', endColorstr='#c21824', GradientType=0);
}
.utopia-glossy .p5 h3 {
    border: 1px solid #af1011;
    box-shadow: 0 2px 2px #e3885b inset;
    background: #d15d30;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2QxNWQzMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNjMTMwMTciIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top, #d15d30 0%, #c13017 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #d15d30), color-stop(100%, #c13017));
    background: -webkit-linear-gradient(top, #d15d30 0%, #c13017 100%);
    background: -o-linear-gradient(top, #d15d30 0%, #c13017 100%);
    background: -ms-linear-gradient(top, #d15d30 0%, #c13017 100%);
    background: linear-gradient(top, #d15d30 0%, #c13017 100%);
    -ms-filter: progid:dximagetransform.microsoft.gradient(startColorstr='#d15d30', endColorstr='#c13017', GradientType=0);
}
.utopia-glossy .p6 h3 {
    border: 1px solid #af9010;
    box-shadow: 0 2px 2px #e3d95b inset;
    background: #d4c130;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2Q0YzEzMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNiOThmMTciIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top, #d4c130 0%, #b98f17 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #d4c130), color-stop(100%, #b98f17));
    background: -webkit-linear-gradient(top, #d4c130 0%, #b98f17 100%);
    background: -o-linear-gradient(top, #d4c130 0%, #b98f17 100%);
    background: -ms-linear-gradient(top, #d4c130 0%, #b98f17 100%);
    background: linear-gradient(top, #d4c130 0%, #b98f17 100%);
    -ms-filter: progid:dximagetransform.microsoft.gradient(startColorstr='#d4c130', endColorstr='#b98f17', GradientType=0);
}
.utopia-glossy .p7 h3 {
    border: 1px solid #93a10a;
    box-shadow: 0 2px 2px #b6d250 inset;
    background: #a7c227;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2E3YzIyNyIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNhY2IwMTEiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top, #a7c227 0%, #acb011 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #a7c227), color-stop(100%, #acb011));
    background: -webkit-linear-gradient(top, #a7c227 0%, #acb011 100%);
    background: -o-linear-gradient(top, #a7c227 0%, #acb011 100%);
    background: -ms-linear-gradient(top, #a7c227 0%, #acb011 100%);
    background: linear-gradient(top, #a7c227 0%, #acb011 100%);
    -ms-filter: progid:dximagetransform.microsoft.gradient(startColorstr='#a7c227', endColorstr='#acb011', GradientType=0);
}
.utopia-glossy .p8 h3 {
    border: 1px solid #4fa10a;
    box-shadow: 0 2px 2px #77cc4d inset;
    background: #61c227;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzYxYzIyNyIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiM2NGIwMTEiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top, #61c227 0%, #64b011 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #61c227), color-stop(100%, #64b011));
    background: -webkit-linear-gradient(top, #61c227 0%, #64b011 100%);
    background: -o-linear-gradient(top, #61c227 0%, #64b011 100%);
    background: -ms-linear-gradient(top, #61c227 0%, #64b011 100%);
    background: linear-gradient(top, #61c227 0%, #64b011 100%);
    -ms-filter: progid:dximagetransform.microsoft.gradient(startColorstr='#61c227', endColorstr='#64b011', GradientType=0);
}
.utopia-glossy .p9 h3 {
    border: 1px solid #13ab86;
    box-shadow: 0 2px 2px #5cdccb inset;
    background: #34ceb5;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzM0Y2ViNSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMxYmJkOGIiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top, #34ceb5 0%, #1bbd8b 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #34ceb5), color-stop(100%, #1bbd8b));
    background: -webkit-linear-gradient(top, #34ceb5 0%, #1bbd8b 100%);
    background: -o-linear-gradient(top, #34ceb5 0%, #1bbd8b 100%);
    background: -ms-linear-gradient(top, #34ceb5 0%, #1bbd8b 100%);
    background: linear-gradient(top, #34ceb5 0%, #1bbd8b 100%);
    -ms-filter: progid:dximagetransform.microsoft.gradient(startColorstr='#34ceb5', endColorstr='#1bbd8b', GradientType=0);
}
.utopia-glossy .p10 h3 {
    border: 1px solid #1859a7;
    box-shadow: 0 2px 2px #5f89d8 inset;
    background: #3b73d4;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzNiNzNkNCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMxZjZkYjQiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top, #3b73d4 0%, #1f6db4 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #3b73d4), color-stop(100%, #1f6db4));
    background: -webkit-linear-gradient(top, #3b73d4 0%, #1f6db4 100%);
    background: -o-linear-gradient(top, #3b73d4 0%, #1f6db4 100%);
    background: -ms-linear-gradient(top, #3b73d4 0%, #1f6db4 100%);
    background: linear-gradient(top, #3b73d4 0%, #1f6db4 100%);
    -ms-filter: progid:dximagetransform.microsoft.gradient(startColorstr='#3b73d4', endColorstr='#1f6db4', GradientType=0);
}
.utopia-glossy .p11 h3 {
    border: 1px solid #797979;
    box-shadow: 0 2px 2px #c8c8c8 inset;
    background: #a4a4a4;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2E0YTRhNCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiM4MzgzODMiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top, #a4a4a4 0%, #838383 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #a4a4a4), color-stop(100%, #838383));
    background: -webkit-linear-gradient(top, #a4a4a4 0%, #838383 100%);
    background: -o-linear-gradient(top, #a4a4a4 0%, #838383 100%);
    background: -ms-linear-gradient(top, #a4a4a4 0%, #838383 100%);
    background: linear-gradient(top, #a4a4a4 0%, #838383 100%);
    -ms-filter: progid:dximagetransform.microsoft.gradient(startColorstr='#a4a4a4', endColorstr='#838383', GradientType=0);
}
.utopia-glossy .p12 h3 {
    border: 1px solid #494949;
    box-shadow: 0 2px 2px #7c7c7c inset;
    background: #626262;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzYyNjI2MiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiM0ZjRmNGYiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top, #626262 0%, #4f4f4f 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #626262), color-stop(100%, #4f4f4f));
    background: -webkit-linear-gradient(top, #626262 0%, #4f4f4f 100%);
    background: -o-linear-gradient(top, #626262 0%, #4f4f4f 100%);
    background: -ms-linear-gradient(top, #626262 0%, #4f4f4f 100%);
    background: linear-gradient(top, #626262 0%, #4f4f4f 100%);
    -ms-filter: progid:dximagetransform.microsoft.gradient(startColorstr='#626262', endColorstr='#4f4f4f', GradientType=0);
}
/* Buttons
-------------------------------------------------------------------*/
.utopia-glossy .utopia-glossy-button {
    border: 1px solid #3d8012;
    box-shadow: 0 2px 2px #76b73e inset;
    background: #51a11b;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzUxYTExYiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMyODZhMGMiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top, #51a11b 0%, #286a0c 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #51a11b), color-stop(100%, #286a0c));
    background: -webkit-linear-gradient(top, #51a11b 0%, #286a0c 100%);
    background: -o-linear-gradient(top, #51a11b 0%, #286a0c 100%);
    background: -ms-linear-gradient(top, #51a11b 0%, #286a0c 100%);
    background: linear-gradient(top, #51a11b 0%, #286a0c 100%);
    -ms-filter: progid:dximagetransform.microsoft.gradient(startColorstr='#51a11b', endColorstr='#286a0c', GradientType=0);
    border-radius: 3px 3px 0 0;
    color: #FFFFFF;
    display: block;
    font-weight: bold;
    line-height: 39px;
    height: 39px;
    margin: 10px auto 0;
    width: 128px;
    text-shadow: 0 -1px 0 #4f8926;
    position: relative;
    bottom: -1px;
}
.utopia-glossy .utopia-glossy-button:hover {
    border: 1px solid #245e08;
    box-shadow: 0 2px 2px #5b9915 inset;
    background: #317408;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzMxNzQwOCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMxNzQzMDMiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top, #317408 0%, #174303 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #317408), color-stop(100%, #174303));
    background: -webkit-linear-gradient(top, #317408 0%, #174303 100%);
    background: -o-linear-gradient(top, #317408 0%, #174303 100%);
    background: -ms-linear-gradient(top, #317408 0%, #174303 100%);
    background: linear-gradient(top, #317408 0%, #174303 100%);
    -ms-filter: progid:dximagetransform.microsoft.gradient(startColorstr='#317408', endColorstr='#174303', GradientType=0);
}
.utopia-glossy .utopia-glossy-button:active {
    border: 1px solid #3c7f12;
    box-shadow: 0 2px 2px #215a04 inset;
    background: #327708;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzMyNzcwOCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMxOTQ1MDMiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top, #327708 0%, #194503 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #327708), color-stop(100%, #194503));
    background: -webkit-linear-gradient(top, #327708 0%, #194503 100%);
    background: -o-linear-gradient(top, #327708 0%, #194503 100%);
    background: -ms-linear-gradient(top, #327708 0%, #194503 100%);
    background: linear-gradient(top, #327708 0%, #194503 100%);
    -ms-filter: progid:dximagetransform.microsoft.gradient(startColorstr='#327708', endColorstr='#194503', GradientType=0);
}
.utopia-glossy .utopia-glossy-button:active span {
    position: relative;
    top: 1px;
}
/* Highlight
-------------------------------------------------------------------*/
.utopia-glossy .highlight h3 {
    margin-top: -13px;
    padding: 10px 0 1px;
}
.utopia-glossy .highlight {
    position: relative;
    padding: 10px 0 0;
    margin-top: -13px;
}
.utopia-glossy .featured {
    background: no-repeat scroll left top transparent;
    display: block;
    height: 87px;
    position: absolute;
    left: -6px;
    text-indent: -9999em;
    top: -7px;
    width: 106px;
}
.utopia-glossy .featured {
    background-image: url("/img/pricingtable_images/glossy/bestvalue1.png");
}
.utopia-glossy .featured.f2 {
    background-image: url("/img/pricingtable_images/glossy/bestvalue2.png");
}
.utopia-glossy .featured.f3 {
    background-image: url("/img/pricingtable_images/glossy/bestvalue3.png");
}
.utopia-glossy .featured.f4 {
    background-image: url("/img/pricingtable_images/glossy/bestvalue4.png");
}
.utopia-glossy .featured.f5 {
    background-image: url("/img/pricingtable_images/glossy/bestvalue5.png");
}
/******************************* ***********************************

                    Minimal Table start

******************************* ***********************************/
.utopia-minimal {
    width: 888px;
    margin: 50px auto;
}
.utopia-minimal .utopia-minimal-plan {
    width: 205px;
    float: left;
    margin-left: 20px;
}
.utopia-minimal .utopia-minimal-plan:first-child {
    margin-left: 0;
}
.utopia-minimal .utopia-minimal-plan h3 {
    font-size: 16px;
    text-align: center;
    color: #fff;
    line-height: 45px;
}
.minmal .utopia-minimal-plan h3 {
    background: #5e923c;
}
.utopia-minimal .utopia-minimal-plan .utopia-minimal-list li:nth-child(2n) {
    background: #fafafa;
}
.utopia-minimal .utopia-minimal-plan .utopia-minimal-list li {
    line-height: 3.8em;
    list-style: none;
}
.utopia-minimal-select,
.utopia-minimal-plan .utopia-minimal-list li {
    text-align: center;
}
.utopia-minimal .utopia-minimal-plan h3 {
    border-radius: 4px 4px 0 0;
}
.utopia-minimal .utopia-minimal-plan .utopia-minimal-select {
    border-radius: 0 0 4px 4px;
}
.utopia-minimal .utopia-minimal-select {
    background: #e7e7e7;
    height: 78px;
    border: 1px solid #c8c8c8;
}
.utopia-minimal .utopia-minimal-select div {
    padding-top: 18px;
    text-shadow: 0 1px 0px #fff;
}
.utopia-minimal .utopia-minimal-features ul {
    border-left: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
    margin: 0;
}
/* specialized
-------------------------------------------------------------------*/
h4 {
    color: #fff;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
}
.utopia-minimal-interval {
    font-size: 14px;
}
.utopia-minimal-amount,
.utopia-minimal-interval {
    display: block;
    text-align: center;
}
.utopia-minimal-amount {
    font-size: 40px;
    line-height: 1em;
}
.utopia-minimal-amount span {
    vertical-align: super;
    font-size: 20px;
}
/* Top
-------------------------------------------------------------------*/
.utopia-minimal .utopia-minimal-p1 h3 {
    background: #6c9e4a;
    background: -moz-linear-gradient(top, #6c9e4a 0%, #578d35 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #6c9e4a), color-stop(100%, #578d35));
    background: -webkit-linear-gradient(top, #6c9e4a 0%, #578d35 100%);
    background: -o-linear-gradient(top, #6c9e4a 0%, #578d35 100%);
    background: -ms-linear-gradient(top, #6c9e4a 0%, #578d35 100%);
    background: linear-gradient(top, #6c9e4a 0%, #578d35 100%);
    -ms-filter: progid:dximagetransform.microsoft.gradient(startColorstr='#6c9e4a', endColorstr='#578d35', GradientType=0);
    border-bottom: 1px solid #4a7c2d;
}
.utopia-minimal .utopia-minimal-p2 h3 {
    background: #5574b1;
    background: -moz-linear-gradient(top, #5574b1 0%, #405fa3 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #5574b1), color-stop(100%, #405fa3));
    background: -webkit-linear-gradient(top, #5574b1 0%, #405fa3 100%);
    background: -o-linear-gradient(top, #5574b1 0%, #405fa3 100%);
    background: -ms-linear-gradient(top, #5574b1 0%, #405fa3 100%);
    background: linear-gradient(top, #5574b1 0%, #405fa3 100%);
    -ms-filter: progid:dximagetransform.microsoft.gradient(startColorstr='#5574b1', endColorstr='#405fa3', GradientType=0);
    border-bottom: 1px solid #365195;
}
.utopia-minimal .utopia-minimal-p3 h3 {
    background: #ce5d26;
    background: -moz-linear-gradient(top, #ce5d26 0%, #c54716 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ce5d26), color-stop(100%, #c54716));
    background: -webkit-linear-gradient(top, #ce5d26 0%, #c54716 100%);
    background: -o-linear-gradient(top, #ce5d26 0%, #c54716 100%);
    background: -ms-linear-gradient(top, #ce5d26 0%, #c54716 100%);
    background: linear-gradient(top, #ce5d26 0%, #c54716 100%);
    -ms-filter: progid:dximagetransform.microsoft.gradient(startColorstr='#ce5d26', endColorstr='#c54716', GradientType=0);
    border-bottom: 1px solid #b3320f;
}
.utopia-minimal .utopia-minimal-p4 h3 {
    background: #906030;
    background: -moz-linear-gradient(top, #906030 0%, #7e4b1e 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #906030), color-stop(100%, #7e4b1e));
    background: -webkit-linear-gradient(top, #906030 0%, #7e4b1e 100%);
    background: -o-linear-gradient(top, #906030 0%, #7e4b1e 100%);
    background: -ms-linear-gradient(top, #906030 0%, #7e4b1e 100%);
    background: linear-gradient(top, #906030 0%, #7e4b1e 100%);
    -ms-filter: progid:dximagetransform.microsoft.gradient(startColorstr='#906030', endColorstr='#7e4b1e', GradientType=0);
    border-bottom: 1px solid #653c18;
}
.utopia-minimal .p5 h3 {
    background: #96975d;
    background: -moz-linear-gradient(left, #96975d 0%, #8c8a50 100%);
    background: -webkit-gradient(linear, left top, right top, color-stop(0%, #96975d), color-stop(100%, #8c8a50));
    background: -webkit-linear-gradient(left, #96975d 0%, #8c8a50 100%);
    background: -o-linear-gradient(left, #96975d 0%, #8c8a50 100%);
    background: -ms-linear-gradient(left, #96975d 0%, #8c8a50 100%);
    background: linear-gradient(left, #96975d 0%, #8c8a50 100%);
    -ms-filter: progid:dximagetransform.microsoft.gradient(startColorstr='#96975d', endColorstr='#8c8a50', GradientType=1);
    border-bottom: 1px solid #656D2D;
}
.utopia-minimal .p6 h3 {
    background: #5f47ad;
    background: -moz-linear-gradient(left, #5f47ad 0%, #4c39a3 100%);
    background: -webkit-gradient(linear, left top, right top, color-stop(0%, #5f47ad), color-stop(100%, #4c39a3));
    background: -webkit-linear-gradient(left, #5f47ad 0%, #4c39a3 100%);
    background: -o-linear-gradient(left, #5f47ad 0%, #4c39a3 100%);
    background: -ms-linear-gradient(left, #5f47ad 0%, #4c39a3 100%);
    background: linear-gradient(left, #5f47ad 0%, #4c39a3 100%);
    -ms-filter: progid:dximagetransform.microsoft.gradient(startColorstr='#5f47ad', endColorstr='#4c39a3', GradientType=1);
    border-bottom: 1px solid #382D7D;
}
.utopia-minimal .p7 h3 {
    background: #52a25a;
    background: -moz-linear-gradient(left, #52a25a 0%, #459749 100%);
    background: -webkit-gradient(linear, left top, right top, color-stop(0%, #52a25a), color-stop(100%, #459749));
    background: -webkit-linear-gradient(left, #52a25a 0%, #459749 100%);
    background: -o-linear-gradient(left, #52a25a 0%, #459749 100%);
    background: -ms-linear-gradient(left, #52a25a 0%, #459749 100%);
    background: linear-gradient(left, #52a25a 0%, #459749 100%);
    -ms-filter: progid:dximagetransform.microsoft.gradient(startColorstr='#52a25a', endColorstr='#459749', GradientType=1);
    border-bottom: 1px solid #0E6216;
}
.utopia-minimal .p8 h3 {
    background: #ad4747;
    background: -moz-linear-gradient(left, #ad4747 0%, #a3393e 100%);
    background: -webkit-gradient(linear, left top, right top, color-stop(0%, #ad4747), color-stop(100%, #a3393e));
    background: -webkit-linear-gradient(left, #ad4747 0%, #a3393e 100%);
    background: -o-linear-gradient(left, #ad4747 0%, #a3393e 100%);
    background: -ms-linear-gradient(left, #ad4747 0%, #a3393e 100%);
    background: linear-gradient(left, #ad4747 0%, #a3393e 100%);
    -ms-filter: progid:dximagetransform.microsoft.gradient(startColorstr='#ad4747', endColorstr='#a3393e', GradientType=1);
    border-bottom: 1px solid #7D2D2D;
}
.utopia-minimal .p9 h3 {
    background: #479aad;
    background: -moz-linear-gradient(left, #479aad 0%, #3995a3 100%);
    background: -webkit-gradient(linear, left top, right top, color-stop(0%, #479aad), color-stop(100%, #3995a3));
    background: -webkit-linear-gradient(left, #479aad 0%, #3995a3 100%);
    background: -o-linear-gradient(left, #479aad 0%, #3995a3 100%);
    background: -ms-linear-gradient(left, #479aad 0%, #3995a3 100%);
    background: linear-gradient(left, #479aad 0%, #3995a3 100%);
    -ms-filter: progid:dximagetransform.microsoft.gradient(startColorstr='#479aad', endColorstr='#3995a3', GradientType=1);
    border-bottom: 1px solid #4a7c2d;
}
.utopia-minimal .utopia-minimal-p10 h3 {
    background: #95ad47;
    background: -moz-linear-gradient(left, #95ad47 0%, #90a339 100%);
    background: -webkit-gradient(linear, left top, right top, color-stop(0%, #95ad47), color-stop(100%, #90a339));
    background: -webkit-linear-gradient(left, #95ad47 0%, #90a339 100%);
    background: -o-linear-gradient(left, #95ad47 0%, #90a339 100%);
    background: -ms-linear-gradient(left, #95ad47 0%, #90a339 100%);
    background: linear-gradient(left, #95ad47 0%, #90a339 100%);
    -ms-filter: progid:dximagetransform.microsoft.gradient(startColorstr='#95ad47', endColorstr='#90a339', GradientType=1);
    border-bottom: 1px solid #6D7E63;
}
.utopia-minimal .utopia-minimal-plan h4 {
    padding: 0 0 10px;
}
.utopia-minimal .utopia-minimal-p1 h4 {
    background: #8bb371;
    box-shadow: 0 1px 1px #9ABE82 inset;
}
.utopia-minimal .utopia-minimal-p2 h4 {
    background: #6179ab;
    box-shadow: 0 1px 1px #738ab6 inset;
}
.utopia-minimal .utopia-minimal-p3 h4 {
    background: #d26529;
    box-shadow: 0 1px 1px #d8773b inset;
}
.utopia-minimal .utopia-minimal-p4 h4 {
    background: #a06d3c;
    box-shadow: 0 1px 1px #ad7f4f inset;
}
.utopia-minimal .p5 h4 {
    background: #9DA03C;
    box-shadow: 0 1px 1px #9DA360 inset;
}
.utopia-minimal .p6 h4 {
    background: #6B658F;
    box-shadow: 0 1px 1px #3E3574 inset;
}
.utopia-minimal .p7 h4 {
    background: #4AAD59;
    box-shadow: 0 1px 1px #47BC59 inset;
}
.utopia-minimal .p8 h4 {
    background: #BA4B4B;
    box-shadow: 0 1px 1px #CF5858 inset;
}
.utopia-minimal .p9 h4 {
    background: #63A3AE;
    box-shadow: 0 1px 1px #79B2DA inset;
}
.utopia-minimal .utopia-minimal-p10 h4 {
    background: #9BAD6A;
    box-shadow: 0 1px 1px #99C44C inset;
}
/* Buttons
-------------------------------------------------------------------*/
.utopia-minimal .button {
    background: #59ac2f;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzU5YWMyZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMyZDg0MTciIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top, #59ac2f 0%, #2d8417 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #59ac2f), color-stop(100%, #2d8417));
    background: -webkit-linear-gradient(top, #59ac2f 0%, #2d8417 100%);
    background: -o-linear-gradient(top, #59ac2f 0%, #2d8417 100%);
    background: -ms-linear-gradient(top, #59ac2f 0%, #2d8417 100%);
    background: linear-gradient(top, #59ac2f 0%, #2d8417 100%);
    -ms-filter: progid:dximagetransform.microsoft.gradient(startColorstr='#59ac2f', endColorstr='#2d8417', GradientType=0);
    border: 1px solid #378d20;
    border-radius: 3px;
    -moz-border-radius: 3px;
    box-shadow: 0px 1px 2px #71c13c inset;
    color: #FFFFFF;
    display: block;
    font-weight: bold;
    line-height: 43px;
    margin: 10px auto 0;
    width: 131px;
    text-shadow: 0 -1px 0 #4f8926;
}
.utopia-minimal .button:hover {
    border: 1px solid #ab7108;
    background: #c1970b;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2MxOTcwYiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNhNDY3MDUiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top, #c1970b 0%, #a46705 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #c1970b), color-stop(100%, #a46705));
    background: -webkit-linear-gradient(top, #c1970b 0%, #a46705 100%);
    background: -o-linear-gradient(top, #c1970b 0%, #a46705 100%);
    background: -ms-linear-gradient(top, #c1970b 0%, #a46705 100%);
    background: linear-gradient(top, #c1970b 0%, #a46705 100%);
    -ms-filter: progid:dximagetransform.microsoft.gradient(startColorstr='#c1970b', endColorstr='#a46705', GradientType=0);
    box-shadow: 0px 1px 2px #d2b20e inset;
}
.utopia-minimal .button:active {
    border: 1px solid #8e6e07;
    box-shadow: 0 1px 2px #af8609 inset;
    background: #be920a;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2JlOTIwYSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiM5ZjYxMDUiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top, #be920a 0%, #9f6105 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #be920a), color-stop(100%, #9f6105));
    background: -webkit-linear-gradient(top, #be920a 0%, #9f6105 100%);
    background: -o-linear-gradient(top, #be920a 0%, #9f6105 100%);
    background: -ms-linear-gradient(top, #be920a 0%, #9f6105 100%);
    background: linear-gradient(top, #be920a 0%, #9f6105 100%);
    filter: progid:dximagetransform.microsoft.gradient(startColorstr='#be920a', endColorstr='#9f6105', GradientType=0);
}
/* Highlight
-------------------------------------------------------------------*/
.utopia-minimal .utopia-minimal-highlight h3 {
    margin-top: -15px;
    padding: 15px 0 0;
}
.utopia-minimal .utopia-minimal-highlight {
    position: relative;
}
.utopia-minimal .utopia-minimal-featured {
    background: no-repeat scroll left top transparent;
    display: block;
    height: 58px;
    position: absolute;
    right: -2px;
    text-indent: -9999em;
    top: -17px;
    width: 95px;
}
.utopia-minimal .utopia-minimal-featured {
    background-image: url("/img/pricingtable_images/minimal/bestvalue1.png");
}
.utopia-minimal .utopia-minimal-featured.f2 {
    background-image: url("/img/pricingtable_images/minimal/bestvalue2.png");
}
.utopia-minimal .utopia-minimal-featured.f3 {
    background-image: url("/img/pricingtable_images/minimal/bestvalue3.png");
}
.utopia-minimal .utopia-minimal-featured.f4 {
    background-image: url("/img/pricingtable_images/minimal/bestvalue4.png");
}
.utopia-minimal .utopia-minimal-featured.f5 {
    background-image: url("/img/pricingtable_images/minimal/bestvalue5.png");
}
/* Pricing tables page css end */

/* Table page css start */
.table thead,
.table tfoot {
    background: #ffffff;
    /* Old browsers */

    background: -moz-linear-gradient(top, #ffffff 0%, #f5f5f5 100%);
    /* FF3.6+ */

    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ffffff), color-stop(100%, #f5f5f5));
    /* Chrome,Safari4+ */

    background: -webkit-linear-gradient(top, #ffffff 0%, #f5f5f5 100%);
    /* Chrome10+,Safari5.1+ */

    background: -o-linear-gradient(top, #ffffff 0%, #f5f5f5 100%);
    /* Opera 11.10+ */

    background: -ms-linear-gradient(top, #ffffff 0%, #f5f5f5 100%);
    /* IE10+ */

    background: linear-gradient(top, #ffffff 0%, #f5f5f5 100%);
    /* W3C */

    filter: progid:dximagetransform.microsoft.gradient(startColorstr='#ffffff', endColorstr='#f5f5f5', GradientType=0);
    /* IE6-9 */

    -webkit-box-shadow: '0 1px 2px rgba(0, 0, 0, 0.1)';
    -moz-box-shadow: '0 1px 2px rgba(0, 0, 0, 0.1)';
    box-shadow: '0 1px 2px rgba(0, 0, 0, 0.1)';
}
.table thead {
    border-top: 1px solid #ddd;
}
.table tfoot {
    border-bottom: 1px solid #ddd;
}
.utopia-table-action {
    margin-top: 25px;
}
table.dataTable {
    margin-top: 0;
}
div.dataTables_length label {
    width: 100%;
    float: left;
    text-align: left;
}
div.dataTables_length select,
div.dataTables_filter input {
    margin-top: -5px;
}
div.dataTables_length label,
div.dataTables_filter label {
    margin: 10px 0;
}
div.dataTables_length select {
    width: 75px;
    float: left;
    margin-right: 10px;
}
div.dataTables_filter label {
    width: 100%;
    float: right;
    text-align: right;
}
div.dataTables_filter input {
    float: right;
    margin-left: 10px;
}
div.dataTables_info {
    padding-top: 10px;
}
div.dataTables_paginate {
    float: right;
    margin: 0;
}
.datatable {
    margin: 1em 0;
    clear: both;
}
table.dataTable th:active {
    outline: none;
}
.datatable .header:after {
    border-color: #000000 transparent;
    border-style: solid;
    border-width: 0 4px 4px;
    content: "";
    float: right;
    margin-top: 7px;
    visibility: hidden;
}
.datatable th {
    cursor: pointer;
}
.datatable .headerSortUp,
.datatable .headerSortDown {
    background: #f8f8f8;
    /* Old browsers */

    background: -moz-linear-gradient(top, #f8f8f8 0%, #ffffff 100%);
    /* FF3.6+ */

    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #f8f8f8), color-stop(100%, #ffffff));
    /* Chrome,Safari4+ */

    background: -webkit-linear-gradient(top, #f8f8f8 0%, #ffffff 100%);
    /* Chrome10+,Safari5.1+ */

    background: -o-linear-gradient(top, #f8f8f8 0%, #ffffff 100%);
    /* Opera 11.10+ */

    background: -ms-linear-gradient(top, #f8f8f8 0%, #ffffff 100%);
    /* IE10+ */

    background: linear-gradient(top, #f8f8f8 0%, #ffffff 100%);
    /* W3C */

    filter: progid:dximagetransform.microsoft.gradient(startColorstr='#ffffff', endColorstr='#f5f5f5', GradientType=0);
    /* IE6-9 */

}
.datatable .header:hover:after {
    visibility: visible;
}
.datatable .headerSortDown:after,
.datatable .headerSortDown:hover:after {
    opacity: 0.6;
    visibility: visible;
}
.datatable .headerSortUp:after {
    border-color: #000000 transparent -moz-use-text-color;
    border-style: solid solid none;
    border-width: 4px 4px medium;
    box-shadow: none;
    opacity: 0.6;
    visibility: visible;
}
a.edit {
    margin: 0 10px;
}
.edit img,
.delete img {
    width: 16px;
}
.utopia-col-1 {
    background: #f7f7f7;
}
/* Table page css end */

/* Typegraphy page css starts */
.utopia-widget {
    border: 1px solid #ddd;
    margin: 0 0 20px;
}
.utopia-widget .utopia-widget-title {
    border-bottom: 1px solid #ddd;
    height: 40px;
    background: #f7f7f7;
    /* Old browsers */

    background: -moz-linear-gradient(top, #f7f7f7 0%, #ffffff 45%, #ffffff 59%, #f7f7f7 100%);
    /* FF3.6+ */

    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #f7f7f7), color-stop(45%, #ffffff), color-stop(59%, #ffffff), color-stop(100%, #f7f7f7));
    /* Chrome,Safari4+ */

    background: -webkit-linear-gradient(top, #f7f7f7 0%, #ffffff 45%, #ffffff 59%, #f7f7f7 100%);
    /* Chrome10+,Safari5.1+ */

    background: -o-linear-gradient(top, #f7f7f7 0%, #ffffff 45%, #ffffff 59%, #f7f7f7 100%);
    /* Opera 11.10+ */

    background: -ms-linear-gradient(top, #f7f7f7 0%, #ffffff 45%, #ffffff 59%, #f7f7f7 100%);
    /* IE10+ */

    background: linear-gradient(top, #f7f7f7 0%, #ffffff 45%, #ffffff 59%, #f7f7f7 100%);
    /* W3C */

    filter: progid:dximagetransform.microsoft.gradient(startColorstr='#f7f7f7', endColorstr='#ffffff', GradientType=0);
    /* IE6-9 */

}
.utopia-widget .utopia-widget-title .utopia-widget-icon {
    display: inline-block;
    height: 18px;
    width: 18px;
    margin: 10px 0 0 15px;
    float: left;
}
.utopia-widget .utopia-widget-title span {
    display: block;
    float: left;
    font: 18px/2.4 'Cuprum', Georgia, serif;
    padding-left: 10px;
}
.utopia-widget .utopia-widget-title .collapse-widget {
    background-image: url(../img/chosen-sprite.png);
    background-repeat: no-repeat;
    background-position: 55% 8px;
    float: right;
}
.utopia-widget .utopia-widget-content {
    padding: 10px 15px;
}
.utopia-widget .utopia-widget-content-nopadding {
    padding: 0px;
}
/* Typegraphy page css ends */

/* Icons Css Start */
/* Icons Css Start */
.utopia-form-social{
    min-height: 440px;
}
.social-box-icon a{
    margin: 0px 15px 10px 0px;
}
.social-box-text-icon a{
    margin: 0px 15px 10px 0px;
}
.utopia-icons ul{
    padding: 0px;
    margin: 0px;
}
.utopia-icons ul li{
    list-style: none;
    float: left;
    padding: 5px 8px;;
    margin-left: 5px;
    border: 1px solid #66cdaa;
    margin-bottom: 10px;
    display: inline;
}

.utopia-icons2-small ul{
    padding: 0px;
    margin: 0px;
}

.utopia-icons2-small ul li{
    border: 1px solid #66CDAA;
    display: inline;
    float: left;
    list-style: none outside none;
    margin-bottom: 10px;
    margin-left: 5px;
    padding: 5px 8px;
    width: 20px;
    display: inline;
}

.utopia-icons2-small-img{
    width: 20px;
    \width: 20px;
}

.utopia-icons2-small ul li img{
    width: 20px;
}
.utopia-icons2-medium ul{
    padding: 0px;
    margin: 0px;
}

.utopia-icons2-medium ul li{
    border: 1px solid #66CDAA;
    display: inline;
    float: left;
    list-style: none outside none;
    margin-bottom: 10px;
    margin-left: 5px;
    padding: 5px 8px;
    width: 40px;
    display: inline;
}

.utopia-icons2-medium-img{
    width: 40px;
    \width: 40px;
}
.utopia-icons2-large ul{

}

.utopia-icons2-large ul li {
    border: 1px solid #66CDAA;
    display: inline;
    float: left;
    list-style: none outside none;
    margin-bottom: 10px;
    margin-left: 5px;
    padding: 5px 8px;
    width: 60px;
    display: inline;
}
.utopia-icons2-large ul li img{
    width: 60px;
    \width: 60px;
    max-width: 100%;
}
.utopia-social-counter-icon{
    margin-bottom: 20px;
}
.utopia-social-counter-icon a{
    margin: 5px 10px;
}
/* Icons Css End */
/* Icons Css End */

/* wizard form Css Start */
.utopia-from-action{
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}
.utopia-form-box{
    /*min-height: 425px;*/
}
.sample-form{
    /*width: 600px;
    margin: auto;*/
    margin-top: 10px;
}
.utopia-from-action{
    margin-bottom: 18px;
    margin-top: 18px;
    width: 400px;
    margin-left: 160px;
}
.utopia-from-action button{
    margin-left: 10px;
    margin-top: 10px;
}
.utopia-form-tabs{
    min-height: 335px;
}
/* wizard form Css end */
